﻿export class GetClosest {
  constructor(elem, selector) {
    // Script for finding the closest parent. Prevents potential structural issues

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                  var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                  while (--i >= 0 && matches.item(i) !== this) { }
                  return i > -1;
                };
    }

    // Get closest match
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }

    return null;
  }
}

export function getCookie(cookieName) {
  const cookieArray = document.cookie.split(';');
  let i;
  let x;
  let y;

  for (i = 0; i < cookieArray.length; i++) {
    x = cookieArray[i].substr(0, cookieArray[i].indexOf('='));
    y = cookieArray[i].substr(cookieArray[i].indexOf('=') + 1);
    x = x.replace(/^\s+|\s+$/g, '');
    if (x === cookieName) {
      return unescape(y);
    }
  }
  return false;
}

export function setCookie(cookieName, value, exmin) {
  const oldDate = new Date();
  const newDate = new Date(oldDate.getTime() + exmin * 60000);
  const newValue = escape(value) + ((exmin == null) ? '' : `; expires=${newDate.toUTCString()};`);
  document.cookie = cookieName + '=' + newValue + '; path=/';
}


export function getParameterByKey(str) {
  str = str.replace(/[[\]]/g, '\\$&');
  const url = window.location.href;
  const regex = new RegExp(`[?&]${str}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function formatBytes(bytes, decimals) {
  if(bytes == 0) return '0 Bytes';
  var k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toggleParentClass(e) {
  let btn = e.target;
  let arrArgs = btn.dataset.args.split(',');
  let selector = arrArgs[0];
  let classesToAdd = arrArgs.filter((arg, index) => index !== 0).map(arg => arg.trim());
  const parent = new GetClosest(btn, selector);
  parent.classList.add(...classesToAdd);
}

export const removesItself = elmt => elmt.parentNode.removeChild(elmt);
