export class Tables {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            const tables = document.querySelectorAll('table');
            tables.forEach(table => {
                const container = document.createElement('div');
                container.classList.add('table-container');
                table.parentNode.insertBefore(container, table);
                container.appendChild(table);
            });
        });
    }
}
