import CarouselViewModel from './carouselViewModel';
import CarouselController from './carouselController';

(function () {
  const carouselBlockSelector = '[data-carousel-multiple]';

  (function () {
    const blocks = document.querySelectorAll(carouselBlockSelector);

    if (!blocks.length) {
      return;
    }

    blocks.forEach((block) => {
      const viewModel = CarouselViewModel(block);
      const controller = new CarouselController(block, viewModel);

      controller.init();

      window.addEventListener('resize', controller.refresh);
    });
  })();
})();
