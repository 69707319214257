export class VideoReload {
    constructor() {
        const reloadButtons = document.querySelectorAll('.reload-button');
        if(!reloadButtons) {
            return
        }

        reloadButtons.forEach(button => {
            button.addEventListener('click', () => {
                location.reload();
            });
        });
    }
}