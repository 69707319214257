export class MegaMenuDropdown {
	static init() {

		function equalHeight(dropdownMenu) {
			let headerHeight = 0;
			let dropdownHeaders = dropdownMenu.querySelectorAll('.js-mm-column .nav-group-header h2');

			for(let i = 0; i < dropdownHeaders.length; i++) {
				if(headerHeight < dropdownHeaders[i].getBoundingClientRect().height) {
					headerHeight = dropdownHeaders[i].getBoundingClientRect().height;
				}
			}

			dropdownHeaders.forEach(header => header.style.height = `${headerHeight}px`)
		}

		function adjustMenuPosition(menu) {
            let mainContainer = menu.closest('.container');
            let dropdownMenu = menu.querySelector('.js-mm-dropdown');
            let difference;

            if(!mainContainer && !dropdownMenu) {
                return;
            }

            // Reset the previous translation
            dropdownMenu.style.transform = 'translateX(-50%) translateX(0)';
            let mainContainerPadding = window.getComputedStyle(mainContainer).getPropertyValue('padding-left');
            let mainContainerPaddingValue = parseInt(mainContainerPadding) || 0;
            let mainContainerClientWidth = mainContainer.offsetWidth - mainContainerPaddingValue * 2
            let mainContainerLeftOffset = mainContainer.getBoundingClientRect().left;
            let mainContainerRightOffset = mainContainerLeftOffset + mainContainerClientWidth;
            let dropdownMenuLeftOffset = dropdownMenu.getBoundingClientRect().left;
            let dropdownMenuRightOffset = dropdownMenuLeftOffset + dropdownMenu.clientWidth;     
            if (dropdownMenuLeftOffset < mainContainerLeftOffset) {
                // dropdown menu should be moved to right
                difference = mainContainerLeftOffset - dropdownMenuLeftOffset + mainContainerPaddingValue;
                dropdownMenu.style.transform = `translateX(-50%) translateX(${difference}px)`;
            } else if (dropdownMenuRightOffset > mainContainerRightOffset) {
                // dropdown menu should be moved to left
                difference = dropdownMenuRightOffset - mainContainerRightOffset - mainContainerPaddingValue;
                dropdownMenu.style.transform = `translateX(-50%) translateX(${-difference}px)`;
            }
        }

        const dropdowns = [...document.querySelectorAll('.dropdown--megamenu')];
        if(!dropdowns.length) return;

        dropdowns.forEach(dropdown => {
            dropdown.addEventListener('shown.bs.dropdown', (relatedTarget) => {
                equalHeight(relatedTarget.currentTarget);
                adjustMenuPosition(relatedTarget.currentTarget);
            });
        });
	}
}