export class InputNumberAdvanced {
    constructor() {
        const inputNumberAdvanced = {
            inputNumbers: document.getElementsByClassName('input-number-advanced'),
        };

        // Only fire functions if a number-input-advanced is in DOM
        if (inputNumberAdvanced.inputNumbers.length) {

            var InputNumber = function(element) {
                this.element = element;
                this.input = this.element.getElementsByClassName('js-number-input__value')[0];
                this.min = parseFloat(this.input.getAttribute('min'));
                this.max = parseFloat(this.input.getAttribute('max'));
                this.step = parseFloat(this.input.dataset.incrementalStep);
                
                if(isNaN(this.step)) this.step = 1;
                this.precision = parseFloat(this.input.dataset.precisionStep);
                initInputNumberEvents(this);
            };

            // update the input attributes (i.e. min, max, step, precision) in case they have been modified since the init that happened on pageload
            var updateInputAttributes = function(input) {
                input.min = parseFloat(input.input.getAttribute('min'));
                input.max = parseFloat(input.input.getAttribute('max'));
                input.step = parseFloat(input.input.dataset.incrementalStep);
                if(isNaN(input.step)) input.step = 1;
                input.precision = parseFloat(input.input.dataset.precisionStep);
                if(isNaN(input.precision)) input.precision = -1;
            }

            var initInputNumberEvents = function(input) {
                // listen to the click event on the custom increment buttons
                input.element.addEventListener('click', function(event){ 
                    var increment = event.target;
                    updateInputAttributes(input);
                    if(increment) updateInputNumber(input, increment);
                });
        
                // when input changes, make sure the new value is acceptable
                input.input.addEventListener('focusout', function(){
                    var value = parseFloat(input.input.value);
                    if( value < input.min ) value = input.min;
                    if( value > input.max ) value = input.max;
                    // check value is multiple of step
                    value = checkIsMultipleStep(input, value);
                    if( value != parseFloat(input.input.value)) input.input.value = value;
                });
            };

            // We'll revisit the following function, along with calculators.js, in another ticket.
            /* eslint-disable-next-line no-unused-vars */
            var getStepPrecision = function(step) {
                // if step is a floating number, return its precision
                return (step.toString().length - Math.floor(step).toString().length - 1);
            };
        
            // update the number after user has clicked an increment button
            var updateInputNumber = function(input, btn) {
                if (btn.nodeName.toLowerCase() === 'input' ){ return false; }
                
                var value = ( btn.classList.contains('number-input__btn--plus') ) ? parseFloat(input.input.value) + input.step : parseFloat(input.input.value) - input.step;
                
                // avoid weird JS math floating point using precisionStep. 
                function retr_dec(num) { // retrieve the amount of decimals from a number in string type
                    return (num.split('.')[1] || []).length;
                }
                // amount of decimals from the precision step
                const precisionDecimals = retr_dec(input.precision.toString());
                // if value has more decimals than the precision step, then limit it to the later
                retr_dec(value.toString()) > precisionDecimals ? value = parseFloat(value.toFixed(precisionDecimals)) : null;
                if( value < input.min ) value = input.min;
                if( value > input.max ) value = input.max;
                input.input.value = value;
            };
        
            // check is MultipleStep when user edit value directly inside input
            var checkIsMultipleStep = function(input, value) {
                // check if editor gave a precision. If not (no precision value set, or step="any"), then skip the checking
                // check if the number inserted is a multiple of the step value (multiply by 1000 to avoid most floating point number)
                const isMultiple =  ( input.precision > 0 ) && (value*1000)%(input.precision*1000) === 0 ? true :  false ;
                
                const editorTranslateErrorMessageStep = document.getElementById('TranslateErrorMessageStep');
                
                const stepErrorMessage = editorTranslateErrorMessageStep !== -1 ? `${editorTranslateErrorMessageStep.value} ${input.precision}` : `Please enter a multiple of ${input.precision}` ;

                isMultiple ? input.input.setCustomValidity('') : input.input.setCustomValidity(stepErrorMessage) ;
                
                return value;
            };
        
            
            for( var i = 0; i < inputNumberAdvanced.inputNumbers.length; i++) {
                (function(i){

                    new InputNumber(inputNumberAdvanced.inputNumbers[i]);
                    
                })(i);
            }
        }

    }
}

// FIXME: if no step precision or step (-1) make values invalid