export class FormsInstantValidation {
    constructor() {

        let formsToBeValidated = document.querySelectorAll('[data-instant-validation="True"]');

        // $$epiforms is a function only present on Episerver forms (so here stop if not an Episerver form + not InstantValidation activated by editor)
        if (typeof $$epiforms == 'undefined' || !formsToBeValidated.length) 
        {
            return null;
        }

        const _epiFormsValidationService = epi.EPiServer.Forms.Validation;

        $$epiforms(document).ready(function myfunction() {
            $$epiforms(".EPiServerForms").on("formsSetupCompleted", function (event) {

                let currentFormDom = event.currentTarget;
                let workingForm = epi.EPiServer.Forms[`${currentFormDom.id}`];
                let textInputs = currentFormDom.querySelectorAll('input[type=text]');
                let verticalChoiceElements = currentFormDom.querySelectorAll('.vertical-choices');

                // maximum number of items validation for Vertical Multiple or Single choice element
                [...verticalChoiceElements].forEach(element => {
                    let maxItems = element.dataset.maxItems;
                    let maxItemsErrorMessage = element.dataset.maxItemsErrorMessage;
                    let errorSpan = document.querySelector(`[data-f-linked-name=${element.dataset.epiformsElementName}]`)

                    const showMaxItemsError = () => {
                        errorSpan.innerHTML = maxItemsErrorMessage;
                        errorSpan.style.display = 'block';
                        element.setAttribute('data-isInvalid', true);
                        element.closest('.Form__Element').classList.add('ValidationFail');
                    }

                    // only validate if the custom validator has been set up (when maxItems is 0, there is no limit, no need to validate)
                    if (maxItems != 0) {
                        element.addEventListener('change', () => {
                            let checked = element.querySelectorAll('input:checked').length;

                            // add event listener do prevent checking and display error when maxItems is reached; loose equality here on purpose, as 'checked' and 'maxItems' are not of same type
                            if (checked == maxItems) {
                                [...element.querySelectorAll('input:not(:checked)')].forEach(input => {
                                    input.disabled = true;
                                    input.parentElement.addEventListener('click', showMaxItemsError);
                                    });
                            // otherwise, remove the error message, enable all inputs and remove the listener
                            } else {
                                [...element.querySelectorAll('input')].forEach(input => {
                                    errorSpan.style.display = 'none';
                                    element.closest('.Form__Element').classList.remove('ValidationFail');
                                    input.disabled = false;
                                    input.parentElement.removeEventListener('click', showMaxItemsError);
                                });
                            }
                        });
                    }
                });

                // list all textInputs with Validation, and add them to textInputsToValidate
                let textInputsToValidate = [];
                [...workingForm.ValidationInfo].forEach(validation => {
                    [...textInputs].forEach(textInput => {
                        if (validation.targetElementId == textInput.id) {
                            textInputsToValidate.push(textInput)
                        }
                    });
                });

                // native EpiForm validatiom methods
                const nativeEpiValidation = event => {
                    let elementDom = event.target;
                    let errorSpan = document.querySelector(`[data-f-linked-name="${elementDom.name}"]`);
                    let validators = _epiFormsValidationService.getElementValidators(workingForm.ValidationInfo, elementDom.id);
                    let validationStatus = _epiFormsValidationService.validateFormValue(elementDom.name, elementDom.value, validators);
                    [...validationStatus].forEach( ( status , indexStatus ) => {

                        // check if one of the validators is a "required". If it is then the instant validation should NOT be triggered by it
                        let isCheckingIfRequired;
                        validators.map( ( validator, indexValidator ) => {
                            if (validator.model.additionalAttributes) {
                                if ('required' in validator.model.additionalAttributes) {
                                    isCheckingIfRequired = indexValidator === indexStatus ? true : false;
                                }
                            }
                        });

                        if (status.isValid == false && !isCheckingIfRequired) { // on Invalid
                            actAfterValidationCheck(elementDom, errorSpan, false, status.message);
                        } else { // on Valid
                            actAfterValidationCheck(elementDom, errorSpan, true)
                        }
                    });

                }

                const prepareFirstOfTwoStatesValidation = (input) => {
                    // check if in 1st or 2nd state validation
                    if (input.dataset.twoStatesStep === undefined) { // preparing 1st state validation
                        input.addEventListener('blur', nativeEpiValidation, false) // check validity on blur
                        input.setAttribute('data-two-states-step', 'first'); // add data-attribute to know which step is on
                    }
                }

                // iterate over textInputs with Validation, and addEventListener to them, for 1st time
                [...textInputsToValidate].forEach(textInput => {
                    prepareFirstOfTwoStatesValidation(textInput);
                });

                const actAfterValidationCheck = (elementDom, errorSpan, isValid, message) => {

                    if (!isValid) {
                        errorSpan.innerHTML = message;
                        errorSpan.style.display = 'block';
                        elementDom.setAttribute('data-isInvalid', true);
                        elementDom.closest('.Form__Element').classList.add('ValidationFail');
                        if (elementDom.dataset.twoStatesStep === 'first' ) { 
                            prepareSecondStateValidation(elementDom);
                        }
                    } else {
                        errorSpan.style.display = 'none';
                        elementDom.closest('.Form__Element').classList.remove('ValidationFail');
                        elementDom.setAttribute('data-isInvalid', false);
                    }

                }

                const prepareSecondStateValidation = (input) => {
                    input.addEventListener('keyup', nativeEpiValidation, false)
                    input.removeEventListener('blur', nativeEpiValidation, false)
                    input.setAttribute('data-two-states-step', 'second');
                }

            });
        });
    }
}