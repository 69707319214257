﻿export class ImageCaption {
    constructor () {        
        let images = document.querySelectorAll('p > img');

        if (!images) {
            return false;
        }

        images.forEach(image => {
            let title = image.title;
            let imageClass = image.classList;

            if (title.length) {            
                let textNode = document.createElement('figcaption');
                textNode.innerText = title;
                
                let figureNode = document.createElement('figure');
                figureNode.classList.add('m-image-caption', ...imageClass);
                
                image.parentNode.insertBefore(figureNode, image);
                figureNode.appendChild(image);
                figureNode.appendChild(textNode);
            }
        });
    }
}