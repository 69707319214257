﻿export class ExternalLinkModal {
    constructor() {

        const modal = document.querySelector('#externalLinkModal');

        if (!modal) {
            return
        }

        const locHostname = window.location.hostname;

        // Remove www.
        let currentDomain = locHostname.replace(/^(www\.|int\.|pre\.)/i, '');
        const currentTopDomain = currentDomain.replace(/^[^.]+\./, '');
        currentDomain = currentDomain.replace(/\..*$/, '');

        document.querySelectorAll('a:not(.country-picker-link)').forEach(element => {
            const elHostname = element.hostname;

            // Remove www.
            let elementDomain = elHostname.replace(/^(www\.|int\.|pre\.)/i, '');
            let elementTopDomain = elementDomain.replace(/^[^.]+\./, '');
            elementDomain = elementDomain.replace(/\..*$/, '');

            if (
            ((currentDomain === elementDomain || elHostname.toLowerCase().includes('yara')) &&
                currentTopDomain !== elementTopDomain) &&
            !element.href.includes('.pdf')
            ) {
            element.classList.add('external_link');
            }
        });

        document.querySelectorAll('a.external_link').forEach(externalLink => {
            externalLink.addEventListener('click', e => {
                e.preventDefault();

                let currentTarget = e.currentTarget;

                // Remove onclick event in case of best bets on Search page and save it for later use
                let elementOnclick = currentTarget.onclick;
                currentTarget.onclick = null;

                let searchLink = currentTarget.dataset.searchLink;
                let url = searchLink ? searchLink : currentTarget.href;
                let text = url.replace(/\+/g, ' ').replace(/&/g, '&amp;');
                let dec = decodeURIComponent(text);

                // Add target url to view
                let anchorTag = modal.querySelector('.targetUrl');
                anchorTag.innerHTML = dec;
                anchorTag.setAttribute('href', url);

                modal.querySelector('.btn-continue').addEventListener('click', () => {
                    if (typeof elementOnclick === 'function') {
                    elementOnclick();
                    } else {
                    window.location.href = url;
                    }
                });

                $(modal).modal('show');
            });
        });
    }
}