export class FormsCustomDependency {
    constructor() {

        let customDependencyElements = document.querySelectorAll('[data-form-custom-dependency]');

        const selectCheck = (element) => element.hasAttribute('data-form-element-selectric');

        if (!customDependencyElements.length) {
            return null;
        }

        let hasSelect = Array.from(customDependencyElements).some(selectCheck);

        if (!hasSelect) return;

        if (typeof $$epiforms !== 'undefined') 
        {
            $$epiforms(document).ready(function myfunction() 
            {
                $$epiforms('.EPiServerForms').on('formsSetupCompleted', () =>
                {
                    const _epiFormsUtils = epi.EPiServer.Forms.Utils;

                    [...customDependencyElements].forEach((element) => {

                        let ghostValuePlaceholder = document.getElementById(`ghostValuePlaceholder_${element.dataset.elementGuid}`);

                            let $element = $(element);
                            let selectDropdown = $(`#${element.dataset.elementGuid}`);
                            $(selectDropdown).change(() => fillValueForDependency(_epiFormsUtils.getElementValue($element), ghostValuePlaceholder))
                    })
                });
            });
        }
        
        const fillValueForDependency = (selectedValues, ghostValuePlaceholder) => {
            ghostValuePlaceholder.setAttribute('value', selectedValues.toString());
            // invoke click event to trigger Epi's js to pick up the values and check against dependency conditions
            $(ghostValuePlaceholder).click()
        }
    }
}