﻿export class SelectLink {
    constructor() {
        const selectionItems = document.querySelectorAll('.js-select-link');

        selectionItems.forEach(item => item.addEventListener('change', function() {
            const url = this.options[this.selectedIndex].dataset.url;

            if (url) {
                window.location.href = url;
            }
        }))
    }
}