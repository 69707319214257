import { makeIframeBlockableByCookieInformation, addPlaceholderAndReload } from '../../cookies/cookieInformationScripts';

export class YoutubePlayer {
    static init() {

        // temporary adding a class to not load for Basic Content and feature page. This component will become obsolete once we refactor Video Block as well. For now, making sure it doesn't fire when not video block
        let videoInDom = document.querySelectorAll('.video-container');
        let isCookiesFeatureToggleEnabled = document.querySelectorAll('[data-use-cookie-information="True"]').length > 0;
        
        // load only if needed
        if (!videoInDom.length) {            
            return null;
        }

        // Load the IFrame Player API code asynchronously.
        let tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/player_api';
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // Replace the ytplayer element with an <iframe> and
        // YouTube player after the API code downloads.
        let player;
        window.onYouTubePlayerAPIReady = () => {
            let youtubeplayers = document.querySelectorAll('[data-bs-target="youtube-player"]');
            
            for (let i = 0; i < youtubeplayers.length; i++) {

                let vidnum = i + 1;
                let jsonvideo = youtubeplayers[i].dataset;
                let parsedjsonvideo = jsonvideo;
                let height = parsedjsonvideo['fullheight'] === "false" ? '290' : '100%';

                youtubeplayers[i].setAttribute("data-key", "video-" + vidnum);

                let playerVars = {
                    'autoplay': parsedjsonvideo['autoplay'],
                    'controls': parsedjsonvideo['showcontrols'],
                    'fs': parsedjsonvideo['showfullscreenoption'],
                    'loop': parsedjsonvideo['loop'],
                    'modestbranding': parsedjsonvideo['modestbranding'],
                    'showinfo': parsedjsonvideo['showinfo'],
                    'rel': parsedjsonvideo['showrelatedcontent'],
                    'mute': parsedjsonvideo['mute']
                };

                if (parsedjsonvideo['playlist'] !== 'false') {
                    playerVars.playlist = parsedjsonvideo['playlist'];
                }

                player = new YT.Player(document.querySelector("[data-key='video-" + vidnum + "']"), {
                    height: height,
                    width: '100%',
                    videoId: parsedjsonvideo['videoid'],
                    playerVars: playerVars,
                    events: {
                        onReady: function (event) {
                            if (parsedjsonvideo['mute'] == 0) 
                                event.target.unMute();
                        }
                    }
                });

                let playerIframe = player.getIframe();

                if (typeof CookieInformation !== "undefined" && isCookiesFeatureToggleEnabled && playerIframe) {
                    makeIframeBlockableByCookieInformation(playerIframe, 'marketing');
                    addPlaceholderAndReload(playerIframe, 'marketing', false);
                }

            }
            
        }
    }
}
