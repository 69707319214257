﻿import { makeIframeBlockableByCookieInformation, addPlaceholderAndReload } from '../cookies/cookieInformationScripts';

export class VideoBlock {
  constructor() {

    const videoModal = document.getElementsByClassName('modal--video');

    if (!videoModal.length) {
      return false;
    }

    const isCookiesFeatureToggleEnabled = document.querySelectorAll('[data-use-cookie-information="True"]').length > 0;

    let videoThumbnails = Array.from(document.getElementsByClassName('video-thumbnail'));

    videoThumbnails.forEach(thumbnail => {
      const id = thumbnail.dataset.videoid;
      this.addVideoThumbnail(thumbnail, id);
    });

    const videoModalsArray = Array.from(videoModal);

    videoModalsArray.forEach(modal => {
      modal.addEventListener('show.bs.modal', (e) => {
        this.unsetFlexboxZindex(true);
        this.openVideo(e.currentTarget, '.video-item', isCookiesFeatureToggleEnabled);
      });

      modal.addEventListener('hide.bs.modal', (e) => {
        const iframe = modal.querySelector('iframe');
        const iframesrc = iframe.getAttribute('src');
        iframe.setAttribute('src', iframesrc);

        this.unsetFlexboxZindex(false);
        // Pauses the YT player if modal is closed
        const myPlayer = e.currentTarget.querySelector('[data-element=\'video\']');
        if (myPlayer) {
          const iframe = myPlayer.contentWindow;
          iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        }
      });
    });

    // Add margin on video item blocks in the same row
    // Check VideoItemBlock for implementation
    const targetRow = document.querySelectorAll('[data-bs-target="#video-add-margin"]');
    targetRow.forEach(elem => {
      // Target parent row from current element's row
      // which is automatically set by EPiServer
      const parent = elem.closest('.row').parentNode.closest('.row');
      parent.classList.add('a-sub-section', 'section--md'); // Add margin on parent row
    });

    return false;
  }

  addVideoThumbnail(thumbnail, id) {

    const responsiveYoutubeThumbnail = () => {
      const thumbnailParentWidth = thumbnail.closest('.video-container').offsetWidth;
      let thumbnailPath = '/default.jpg';
      let ratioClass = 'ratio-4-3';

      if(thumbnailParentWidth > 1000) {
        thumbnailPath = '/maxresdefault.jpg';
        ratioClass = 'ratio-16-9';
      } else if (thumbnailParentWidth > 500) {
        thumbnailPath = '/sddefault.jpg';
      } else if (thumbnailParentWidth > 400) {
        thumbnailPath = '/hqdefault.jpg';
      } else if (thumbnailParentWidth > 300) {
        thumbnailPath = '/mqdefault.jpg';
        ratioClass = 'ratio-16-9';
      }

      return { 'thumbnailPath': thumbnailPath, 'ratioClass': ratioClass };
    };

    let responsiveSource = `https://img.youtube.com/vi/${id}${responsiveYoutubeThumbnail().thumbnailPath}`;

    const img = new Image();
    img.onload = function () {
      // Check if it's the Youtube missing thumbnail image.
      if (this.width === 120 && this.height === 90) {
        responsiveSource = `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
      }

      thumbnail.style.backgroundImage = `url(${responsiveSource})`;
      thumbnail.classList.remove('d-none');
      thumbnail.classList.add(responsiveYoutubeThumbnail().ratioClass);
    };

    img.src = responsiveSource;
  }

  openVideo(e, videoItemClass, isCookiesFeatureToggleEnabled) {
    const videoItemContainer = e.querySelector(videoItemClass);

    const settings = {
      videoid: videoItemContainer.dataset.videoid,
      autoplay: videoItemContainer.dataset.autoplay,
      showcontrols: videoItemContainer.dataset.showcontrols,
      showfullscreenoption: videoItemContainer.dataset.showfullscreenoption,
      loop: videoItemContainer.dataset.loop,
      modestbranding: videoItemContainer.dataset.modestbranding,
      showinfo: videoItemContainer.dataset.showinfo,
      showrelatedcontent: videoItemContainer.dataset.showrelatedcontent,
      mute: videoItemContainer.dataset.mute,
      playlist: videoItemContainer.dataset.playlist
    };

    // To avoid creating a new iframe if it exists
    if (!videoItemContainer.querySelector('iframe')) {
      let video = document.createElement("iframe");
      video.id = `video-${settings.videoid}`;
      video.setAttribute("videoid", settings.videoid);
      video.setAttribute("frameborder", "0");
      video.setAttribute("allowfullscreen", "");
      video.setAttribute("data-element", "video");
      video.setAttribute("allow", "autoplay"); // needed for Chrome to enable autoplay

      video.src = `https://www.youtube.com/embed/${settings.videoid}?autoplay=${
        settings.autoplay
      }&controls=${settings.showcontrols}&fs=${
        settings.showfullscreenoption
      }&loop=${settings.loop}&modestbranding=${
        settings.modestbranding
      }&showinfo=${settings.showinfo}&rel=${settings.showrelatedcontent}&mute=${
        settings.mute
      }${
        settings.playlist === "true" ? `&playlist=${settings.playlist}` : ""
      }&enablejsapi=1`; // to allow pausing video from script

      if (isCookiesFeatureToggleEnabled) {
        video = makeIframeBlockableByCookieInformation(video, "marketing");
      }

      videoItemContainer.append(video);

      if (
        typeof CookieInformation !== "undefined" &&
        isCookiesFeatureToggleEnabled
      ) {
        addPlaceholderAndReload(video, "marketing", true);
      }
    }
  }

  // unset z-index for flexbox to prevent it from hiding the modal (true), and set it back once modal closes (false)
  unsetFlexboxZindex(shouldUnset) {
    const flexBlocks = document.getElementsByClassName('flexbox-block');
    if (flexBlocks.length) {
      [...flexBlocks].forEach(block => {
        if (shouldUnset) {
          block.style.zIndex = 'unset';
        } else if (block.parentElement.classList.contains('mobile-header-flexbox-container')) {
          block.style.zIndex = '';
        } else {
          block.style.zIndex = '1';
        }
      });
    }
  }
}
