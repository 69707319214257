﻿export class IconBlock {
    constructor() {
        const iconBlocks = document.querySelectorAll('.js-icon-block');
        
        if (!iconBlocks.length) {
            return false;
        }

        for (let block of iconBlocks) {
            let parent = block.closest('.m-flex-content-area');
            
            if (parent) {
                parent.classList.add('icon-block-container');
            }
        }        
    }
}