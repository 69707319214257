﻿const navigationMobile = document.querySelector('.navigation-mobile');

const mobileMenuNav = document.querySelector('.white-menu-mobile');
const breadcrumbContainer = document.querySelector('.breadcrumbs-container');
const bannerImages = document.querySelectorAll('[data-banner-image]')
const crisis = document.querySelector('[data-crisis-modal]');
const crisisCloseBtn = crisis?.querySelector('.close');

export const adjustContainerPositionAccordingToHeader = () => {
    if(!mobileMenuNav) {
        return
    }

    const outerContainer = mobileMenuNav.closest('.a-outer-container')
    outerContainer.classList.add('main-white-menu')
    let mobileMenuNavHeight = mobileMenuNav.offsetHeight
    let breadcrumbContainerHeight = 0
    let crisisHeight = 0

    if (breadcrumbContainer && window.matchMedia("(max-width: 1200px)").matches) {
        breadcrumbContainerHeight = breadcrumbContainer.offsetHeight
    }

    if (crisis) {
        crisisHeight = crisis.offsetHeight

        crisisCloseBtn?.addEventListener('click', () => {
            crisisHeight = 0
            outerContainer.style.paddingTop = `${mobileMenuNavHeight + breadcrumbContainerHeight + crisisHeight}px`
            bannerImages.forEach(banner => banner.style.top = `${mobileMenuNavHeight + breadcrumbContainerHeight + crisisHeight}px`)
        })
    }

    outerContainer.style.paddingTop = `${mobileMenuNavHeight + breadcrumbContainerHeight + crisisHeight}px`
    bannerImages.forEach(banner => banner.style.top = `${mobileMenuNavHeight + breadcrumbContainerHeight + crisisHeight}px`)
}

adjustContainerPositionAccordingToHeader();

addEventListener('resize', () => {
    adjustContainerPositionAccordingToHeader();
});

if (navigationMobile) {
    const mainMenuContainers = navigationMobile.querySelectorAll('[data-menu-level="1"]');

    const mainMenuItems = navigationMobile.querySelectorAll('[data-menu-level="1"] > [data-has-submenu]');

    const subMenuPlaceholder = navigationMobile.querySelector('[data-menu-placeholder]');

    const hideMainMenu = () => mainMenuContainers.forEach((menu) => menu.classList.remove('show'));
    const showMainMenu = () => mainMenuContainers.forEach((menu) => menu.classList.add('show'));

    const clearPlaceholder = () => subMenuPlaceholder.innerHTML = '';

    const backToMenu = (level, menuToShow) => {
        clearPlaceholder();

        if (level === 1) {
            showMainMenu();
        } else {
            const previousMenu = menuToShow.closest(`[data-menu-level="${level}"]`);
            revealSubMenu(previousMenu, level);
        }
    }

    const revealSubMenu = (menuToShow, level) => {
        hideMainMenu();

        const clone = menuToShow.cloneNode(true);

        const back = clone.querySelector('[data-back] .icon-carret-left');
        back.addEventListener('click', () => backToMenu(level - 1, menuToShow));

        clearPlaceholder();
        clone.classList.add('show');
        subMenuPlaceholder.append(clone);

        const cloneMenuItems = clone.querySelectorAll(`[data-menu-level="${level}"] > [data-has-submenu]`);

        cloneMenuItems.forEach((clone) => itemHandler(clone, level + 1));
    }

    const itemHandler = (item, level) => {
        const menuToShow = item.nextElementSibling.querySelector(`[data-menu-level="${level}"]`);

        const back = menuToShow.querySelector('[data-back]');

        const hasLink = back.dataset.link !== '';

        back.innerHTML = hasLink ?
         `<span class="icon icon-carret-left"></span><a href="${back.dataset.link}" class="menu-text has-link">${item.innerText}</a>` :
         `<span class="icon icon-carret-left"></span><span class="menu-text">${item.innerText}</span>`;


        item.addEventListener('click', () => revealSubMenu(menuToShow, level));
    }

    mainMenuItems.forEach((item) => itemHandler(item, 2));
}