import { iframeCIfixer } from './cookieInformationScripts';
import { removesItself } from '../common/helpers';

// crawls pages to find TinyMCE iframes already "pluginized" but by a previous version of the plugin, so update the DOM accordingly to new versions
const tinyMceIframesPluginized = [...document.querySelectorAll('.common-text > p + p.consent-placeholder')];

if (tinyMceIframesPluginized.length) {
  tinyMceIframesPluginized.forEach(oldPlaceholder => {
    if (oldPlaceholder.previousElementSibling.querySelectorAll('iframe').length) {
      iframeCIfixer(oldPlaceholder.previousElementSibling.querySelectorAll('iframe')[0]);
      removesItself(oldPlaceholder);
    }
  });
}
