export class FormsSendToTealium {
    constructor() {
        if (typeof $$epiforms !== "undefined") {
            $$epiforms(".EPiServerForms").on("formsSubmitted", function (ev) {
                let _formsUtils = epi.EPiServer.Forms.Utils;
                let formElements = ev.target;

                let originalSubmitButton =
                    formElements.querySelector(".FormSubmitButton");
                let shouldSendToTealium = originalSubmitButton.dataset.sendToTealium;

                if (shouldSendToTealium === "True") {
                    let formType =
                        originalSubmitButton.dataset.formType !== ""
                            ? originalSubmitButton.dataset.formType
                            : "Not selected";
                    let formTitle = originalSubmitButton.dataset.formTitle;
                    let totalSteps = ev.workingFormInfo.StepsInfo.Steps.length;
                    let currentStep =
                        _formsUtils.getCurrentStepIndex(ev.workingFormInfo) + 1;
                    let stepToSend =
                        currentStep === totalSteps ? "final" : currentStep.toString();

                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        event: "form_submit",
                        form_type: formType,
                        form_title: formTitle,
                        form_step: stepToSend,
                    });
                    if (window.utag && utag.link) {
                        utag.link({
                            event: "form_submit",
                            form_type: formType,
                            form_title: formTitle,
                            form_step: stepToSend,
                        });
                    }
                }
            });
        }
    }
}
