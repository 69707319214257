const containerToBeAnimatedOnThePage =
  document.querySelectorAll('.animate-container');

export const animateContent = () => {
  const itemsToBeAnimatedOnThePage = () =>
    document.querySelectorAll('.animate-container .fade-in-up');

  if (!itemsToBeAnimatedOnThePage().length > 0) {
    return;
  }

  const observerOptions = {
    threshold: 0,
  };
  const animatedContentObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animated');
        animatedContentObserver.unobserve(entry.target);
      }
    });
  }, observerOptions);

  if (itemsToBeAnimatedOnThePage()) {
    itemsToBeAnimatedOnThePage().forEach((elementAnimation) =>
      animatedContentObserver.observe(elementAnimation),
    );
  }
};
if (containerToBeAnimatedOnThePage.length) animateContent();
