﻿export class FormSubmitButtonSpinner {
  constructor() {
    if (typeof $$epiforms !== "undefined") {

      let formElements;
      let submitButton;
      epi.EPiServer.Forms.AsyncSubmit = true;

        $$epiforms(".EPiServerForms").on("formsStartSubmitting", function(ev) { 
          formElements = ev.target;
          let isYaraStepSubmit = formElements.querySelector('[data-this-is-submit="True"]');          
          submitButton = isYaraStepSubmit ? formElements.querySelector('[data-submit-button]') : formElements.querySelector('.FormSubmitButton');
          submitButton.classList.add('button-loading-spinner');
          submitButton.setAttribute('disabled', 'disabled');
        });

          $$epiforms(".EPiServerForms").on("formsSubmittedError formsNavigationNextStep formsNavigationPrevStep", function (ev) {
           formElements = ev.target;
           let isYaraStepSubmit = formElements.querySelector('[data-this-is-submit="True"]')          
           submitButton = isYaraStepSubmit ? formElements.querySelector('[data-submit-button]') : formElements.querySelector('.FormSubmitButton');
           submitButton.classList.remove('button-loading-spinner');
           submitButton.removeAttribute('disabled');
        });
    }
  }
}
