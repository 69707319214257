﻿export class SectionIntro {
    constructor() {
        const sectionBlocks = document.querySelectorAll('.dynamicsectionintroblockmodel, .sectionintroblockmodel');

        if (!sectionBlocks.length) {
            return
        }

        sectionBlocks.forEach(block => {
            // Select blocks with m-flex-content-area class.
            // We must use these selectors because these are set by Episerver.
            // .icon-block-container class is set in js/components/iconblock/index.js
            // Vacancies should take full width.
            // Icon blocks currently have max-width: 25%.

            const count = block.querySelectorAll('.m-flex-content-area>.row')?.length;
            const exceptionsCount = block.querySelectorAll('.m-flex-content-area>.vacancydatamodel, .icon-block-container')?.length;

            if(count > 4 && exceptionsCount === 0) {
                block.classList.add('multiple-rows');
            }
        })
    }
}