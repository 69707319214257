export class DropdownMenu {
  static init() {
    const dropdownMenus = document.querySelectorAll('.js-dropdown-menu');

    dropdownMenus.forEach(dropdown => {
      const childrenCount = dropdown.children.length;
      let anchorList = [];

      if (childrenCount > 4) {
        dropdown.classList.add('dropdown--split');

        // Creating a 2 column structure inside the dropdown
        const row = document.createElement('div');
        row.className = 'row g-0';

        const col1 = document.createElement('div');
        col1.className = 'col-md-6 js-dropdown-left';

        const col2 = document.createElement('div');
        col2.className = 'col-md-6 js-dropdown-right';

        row.append(col1, col2);
        dropdown.appendChild(row);

        // Adding anchors inside dropdown to anchorList array
        dropdown.querySelectorAll('.dropdown-item').forEach(anchor => {
          anchorList.push(anchor);
        });

        // Going through the anchorList and adding each anchor to the left div or right div
        anchorList.forEach((anchor, index) => {
          index % 2 === 0 ? dropdown.querySelector('.js-dropdown-left').append(anchor) : dropdown.querySelector('.js-dropdown-right').append(anchor);
        });
      }
    });
  }
}