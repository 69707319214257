import syncActiveSlideWithIndicators from './syncActiveSlideWithIndicators';

class ImageGalleryCarouselController {
    current = 0;

    constructor(blockElement, viewModel) {
        this.blockElement = blockElement;
        this.viewModel = viewModel;
    }

    _toggleItemsVisibility = () => {
        let display = 'none';

        if(!this.viewModel.isDeficiencyPage) {
            if(window.innerWidth <= 992) {
                display = 'flex';
            }
        }

        for (let i = 0; i < this.viewModel.galleryItems.length; i++) {
            this.viewModel.galleryItems[i].style.display = display;
        }
    }

    _reset = () => {
        this._toggleItemsVisibility();

        this.viewModel.galleryItems[this.current].style.display = 'flex';
    }

    _updateArrowsState = () => {
        this.viewModel.arrowLeft.disabled = this.current === 0;
        this.viewModel.arrowRight.disabled = this.current === this.viewModel.galleryItems.length - 1;
    }

    _showLess = () => {
        this.viewModel.showLessBtns.forEach(button => {
            const slideItem = button.closest('.expanded[data-carousel-slide-item]')
            slideItem?.classList.remove('expanded')
            slideItem?.classList.add('shrinked')
        });
    }

    _showNext = () => {
        if (this.viewModel.isDeficiencyPage) this._showLess();
        this._toggleItemsVisibility();
        this.viewModel.galleryItems[this.current + 1].style.display = 'flex';
        this.current++;
        this._updateArrowsState();
        if (this.viewModel.isDeficiencyPage) this.#hideShowMoreBtn(this.viewModel.galleryItems[this.current]);
    }

    _showPrevious = () => {
        if (this.viewModel.isDeficiencyPage) this._showLess();
        this._toggleItemsVisibility();
        this.viewModel.galleryItems[this.current - 1].style.display = 'flex';
        this.current--;
        this._updateArrowsState();
        if (this.viewModel.isDeficiencyPage) this.#hideShowMoreBtn(this.viewModel.galleryItems[this.current]);
    }

    #syncActiveSlideWithIndicators = () => {
        const { slidesToSyncWithIndicators, getIndicatorDots} = this.viewModel;

        syncActiveSlideWithIndicators({
            targetElements: slidesToSyncWithIndicators,
            getIndicatorDots,
        });
    };

    #toggleMoreLess = (button) => {
        const slideItem = button.closest('[data-carousel-slide-item]')
        const showMoreBtn = slideItem.querySelector('.show-more');
        if(slideItem.classList.contains('expanded')) {
            showMoreBtn.style.display = "block";
            slideItem.classList.remove('expanded')
            slideItem.classList.add('shrinked')
            return;
        }

        showMoreBtn.style.display = "none";
        slideItem.classList.remove('shrinked')
        slideItem.classList.add('expanded')
    }

    #hideShowMoreBtn = (galleryItem) => {
            const container = galleryItem.querySelector('[data-gallery-scrollable]');
            const hiddenContent = galleryItem.querySelector('[data-paragraph-scrollable]');
            const containerClientHeight = container.clientHeight;
            const showMoreBtn = container.querySelector('.show-more');
            const bufferValue = 10;
            const hiddenContentHeight = hiddenContent.scrollHeight + bufferValue;

            // Check if the content is taller than the visible height of the container
            if (hiddenContentHeight < containerClientHeight) {
                showMoreBtn.style.display = "none";
            } else {
                showMoreBtn.style.display = "block";
            }
    }

    #addShowMoreListener = () => {
        this.viewModel.showMoreBtns.forEach(button => {
            button.addEventListener("click", e => {
                e.preventDefault()
                this.#toggleMoreLess(button)
            });
        });
    };

    #addShowLessListener = () => {
        this.viewModel.showLessBtns.forEach(button => {
            button.addEventListener("click", e => {
                e.preventDefault()
                this.#toggleMoreLess(button)
            });
        });
    }

    refresh = () => {
        if(this.viewModel.galleryItems.length > 1) {
            this.#syncActiveSlideWithIndicators();
        }
        this._reset();
    };

    init = () => {
        const self = this;
        this._reset();
        if(this.viewModel.galleryItems.length > 1) {
            this.viewModel.arrowLeft.addEventListener('click', this._showPrevious);
            this.viewModel.arrowRight.addEventListener('click', this._showNext);
            this.#syncActiveSlideWithIndicators();
        }
        if(this.viewModel.isDeficiencyPage) {
            this.#hideShowMoreBtn(this.viewModel.galleryItems[this.current]);
            // Call the function on window resize
            window.addEventListener('resize', function() {
                self.#hideShowMoreBtn(self.viewModel.galleryItems[self.current]);
            });
        }
        this.#addShowMoreListener();
        this.#addShowLessListener();
    }

}

export default ImageGalleryCarouselController;