﻿export default class SearchFilter {

    filterAll = 'all';

    constructor() {
        this.parseQueries = this.parseQueries.bind(this);
        this.scrollTo = this.scrollTo.bind(this);
        this.handleShowMoreResults = this.handleShowMoreResults.bind(this);
        this.searchElement = document.querySelector('.p-search');
        this.init();
    }

    parseQueries() {
        let queries = {};

        const { location: { search } } = window;
        let searchParams = new URLSearchParams(search);

        for (const key of searchParams.keys()){
            queries[key] = searchParams.get(key);
        }

        return {
            ...queries,
            pageCurrentSite: parseInt(queries.pageCurrentSite || 1),
        };
    }

    scrollTo(element) {
        if (!element) {
            return false;
        }

        const offset = element.offsetTop;
        const duration = 1000;
        const start = window.pageYOffset || document.documentElement.scrollTop;
        const distance = offset - start;
        let currentTime = 0;

        const easeOutCubic = function(t) {
            t--;
            return t * t * t + 1;
        };

        const animateScroll = function() {
            currentTime += 16;

            const position = easeOutCubic(currentTime / duration) * distance + start;

            window.scrollTo(0, position);

            if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
            } else {
            element.focus();
            if (document.activeElement !== element) {
                element.setAttribute('tabindex', '-1');
                element.focus();
            }
            }
        };

        animateScroll();
    }

    getPageFirstElement(page) {
        const pageCount = parseInt(document.querySelector('#resultLimit').value);
        const target = document.querySelectorAll('.jsSearchHits .search__hit');
        const hitIndex = target.length < pageCount ? 0 : (page * pageCount) - (pageCount);

        return target[hitIndex];
    }

    handleShowMoreResults(e) {
        e.preventDefault();
        const queries = this.parseQueries();

        const query = new URLSearchParams(
            Object.entries({
                ...queries,
                pageCurrentSite: queries.pageCurrentSite + 1,
            })
        ).toString();

        const { location: { origin, pathname } } = window;
        window.location.href = `${origin}${pathname}?${query}`;
    }

    init() {
        if (this.searchElement) {
            const { pageCurrentSite } = this.parseQueries();

            // Scroll to current hits
            if (pageCurrentSite > 1) {
                const pageFirstRow = this.getPageFirstElement(pageCurrentSite);
                this.scrollTo(pageFirstRow);
            }

            // Show more event listener
            document.querySelector('#getMoreResults')?.addEventListener('click', this.handleShowMoreResults);
        }
    }
}

const searchElement = document.querySelector('.p-search');

if (searchElement) {
    const searchContactCards = document.querySelectorAll('.contactblockmodel');

    if (searchContactCards) {
        const getUncle = (elemToStart, uncleSelector) => elemToStart.parentNode.parentNode.querySelector(uncleSelector);
        const pSearch = document.querySelector('.p-search');
        const button = getUncle(pSearch, 'button');

        pSearch?.addEventListener('show.bs.collapse', () => button.classList.add('rotated'));
        pSearch?.addEventListener('hide.bs.collapse', () => button.classList.remove('rotated'));
    }
}