﻿import { GetClosest } from "../common/helpers/index";

export class IframeResize {
    constructor() {
        const iframeBlocks = document.querySelectorAll("[data-target='#iframe-block']");

        if (!iframeBlocks.length)
            return false;

        for (var i = 0; i < iframeBlocks.length; i++) {
            const iframe = iframeBlocks[i];
            const parent = new GetClosest(iframe, ".iframeblockmodel")?.classList; // Get parent classlist

            // Get initial iframe width to calculate video ratio
            let initWidth;
            if (parent?.contains("full")) {
                initWidth = 1140;
            } else if (parent?.contains("twothirds")) {
                initWidth = 730;
            } else if (parent?.contains("half")) {
                initWidth = 540;
            }
            else {
                initWidth = iframe.clientWidth;
            }

            const isVideo = iframe.dataset.video; // Checks if iframe is a video
            const heights = iframe.dataset.heights.split(","); // Gets mobile and desktop heights
            let heightDesktop = heights[0] === ("0") ? 150 : Number(heights[0]); // 150px is the default height of an iframe
            let heightMobile = heights[1] === ("0") ? heightDesktop : Number(heights[1]); // If mobile height is not set, use desktop height
            let ratio = heightDesktop / initWidth; // Used to calculate new height

            // Update height on function call
            const resizeIframe = function () {
                let iframeWidth = iframe.clientWidth;
                let windowWidth = window.innerWidth;

                // Check if iframe is video AND if desktop height is set
                if (isVideo === "true" && heights[0] !== "0") {

                    // Calculate new height
                    let calcHeight = Math.ceil(ratio * iframeWidth) + 30; // Add 30px on calculated height as a buffer for resizing
                    let newHeight = ((iframeWidth > 1140) && (heightDesktop <= calcHeight)) ? heightDesktop : calcHeight; // Use desktop height as max height
                    iframe.setAttribute("height", newHeight);
                } else {

                    // Use mobile and desktop height respectively based on window width
                    if (windowWidth <= 991) {
                        iframe.setAttribute("height", heightMobile);
                    } else {
                        iframe.setAttribute("height", heightDesktop);
                    }
                }
            };
            window.addEventListener("load", resizeIframe); // Updates height on load
            window.addEventListener("resize", resizeIframe); // Updates height on resize
        }
        return false;
    }
}