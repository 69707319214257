document.addEventListener("DOMContentLoaded", () => {
    let mainContent = document.getElementById('maincontent');
    const bodyElement = document.querySelector('body');
    const skipButton = document.getElementById('skiptomaincontent');
    const classesToCheck = ['LandingPageModel', 'ConnectionStartPageModel', 'AccelerateStartPageModel'];

    if (classesToCheck.some(cls => bodyElement.classList.contains(cls))) {
        mainContent = document.getElementById('main-content-container');
        if (mainContent.innerHTML.trim() === '') {
            skipButton.style.display = 'none';
            return;
        }
    }

    skipButton.addEventListener('keydown', function(event) {
        if (event.code === 'Space' || event.code === 'Enter') {
            event.preventDefault();
            mainContent.scrollIntoView({behavior: 'smooth' });

            const focusableElements = mainContent.querySelectorAll(`
                a,
                button:not(:disabled),
                input:not([type="hidden"]):not(:disabled),
                textarea:not(:disabled),
                select:not(:disabled),
                [tabindex]:not([tabindex="-1"])
            `);

            if (focusableElements.length > 0) {
                focusableElements[0].focus();
            }
        }
    });
});