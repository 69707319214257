import syncActiveSlideWithIndicators from './syncActiveSlideWithIndicators';

class CarouselController {
  _remainingScrollableWidth;
  _scrollFactor;

  _currentSlideIndex = 0;
  _scrollMoveEnd = 0;
  _itemGap = 32;
  _containerPadding = 30;

  _lastMove;
  _isScrolling;
  _lastMoveRecorded = 0;

  constructor(blockElement, viewModel) {
    this.blockElement = blockElement;
    this.viewModel = viewModel;
  }

  _calculateLastMove() {
    // carousel should end in containerRightPosition
    const container = this.viewModel.arrows.parentNode;
    const containerRightPosition = Math.round(container.getBoundingClientRect().right)

    // when lastSlideRightPosition is equal to containerRightPosition = that's the end of scrolling
    const lastSlide = this.viewModel.blockItems[this.viewModel.blockItems.length - 1]
    const lastSlideRightPosition = Math.round(lastSlide.getBoundingClientRect().right)

    return lastSlideRightPosition - containerRightPosition + this._containerPadding/2
  }

  _calculateIfShouldHaveFirstStepLonger() {
    // difference between carousel full width and the carousel scrollable area width will calculate if there are enough slides to make the first move longer in order to keep the carousel aligned with right side of container
    const carouselScrollableAreaWidth = this.viewModel.carouselBlock.clientWidth; // 1470
    const carouselWidth = this.viewModel.itemsContainer.scrollWidth;
    const differenceBetweenCarouselAreaAndWidth = Math.abs(carouselScrollableAreaWidth - carouselWidth)

    return differenceBetweenCarouselAreaAndWidth <= this.viewModel.singleItem.clientWidth
  }

   _calculateScrollFactorToLeft() {
     // the last slide has no following gap
      const slideItemWidth = this.viewModel.singleItem.clientWidth; // 272
      const slideItemWithGap = this.viewModel.singleItem.clientWidth + this._itemGap; // 304

      const hasFirstStepLonger = this._calculateIfShouldHaveFirstStepLonger()

      if(this._lastMove <= slideItemWidth + this._containerPadding*2) {
        this._lastMoveRecorded = this._lastMove;

        this.viewModel.scrollRight.disabled = true
        this.viewModel.scrollLeft.disabled = false

        return this._lastMove
      }

      if(hasFirstStepLonger) {
        this._lastMoveRecorded = this._lastMove;

        this.viewModel.scrollLeft.disabled = false
        this.viewModel.scrollRight.disabled = true

        return this._lastMove
      }

      if (this._currentSlideIndex === 0) {
        // make longer slide move with first click to right
        this.viewModel.scrollLeft.disabled = false

        return this.viewModel.singleItem.clientWidth + parseInt(this.viewModel.scrollTolerance, 10) + this._itemGap
      }

      return slideItemWithGap
   }

  _calculateScrollFactorToRight() {
    if(this._lastMove === 0) {
      const hasFirstStepLonger = this._calculateIfShouldHaveFirstStepLonger()

      if(hasFirstStepLonger) {
        this.viewModel.scrollLeft.disabled = true
      }
      this.viewModel.scrollRight.disabled = false

      return this._lastMoveRecorded
    }

    if(this._lastMove <= this._containerPadding/2) {
      if (this._currentSlideIndex === 1) {
        this.viewModel.scrollLeft.disabled = true
      }

      this.viewModel.scrollRight.disabled = false

      return this._lastMoveRecorded
    }

    if (this._currentSlideIndex === 1) {
      this.viewModel.scrollLeft.disabled = true
      this.viewModel.scrollRight.disabled = false

      return this.viewModel.singleItem.clientWidth + parseInt(this.viewModel.scrollTolerance, 10) + this._itemGap;
    }

    if (this._currentSlideIndex === 0) {
      this.viewModel.scrollLeft.disabled = true
    }

    return this.viewModel.singleItem.clientWidth + this._itemGap;
  }

  _refreshArrows = () => {
    if (this.viewModel.blockItems.length < 5) {
      this.blockElement.classList.add('limited')
    }

    const containerWidth = this.viewModel.itemsContainer.clientWidth;
    const allSlidesWidthWithoutGaps = this.viewModel.singleItem.clientWidth * this.viewModel.blockItems.length
    let carouselWidth = allSlidesWidthWithoutGaps + this._itemGap * (this.viewModel.blockItems.length - 1)

    const tolerance = 5

    if(carouselWidth - tolerance > containerWidth) {
      this.viewModel.arrows.style.display = 'block';
    }
  };

  _animate = (start, end, duration) => {
    const keyframes = [
      { transform: `translateX(${start}px)` },
      { transform: `translateX(${end}px)` },
    ];
    const options = {
      duration: duration,
      fill: `forwards`,
      iterations: 1,
      easing: "linear",
    };

    const animation = new Animation(
      new KeyframeEffect(this.viewModel.itemsContainer, keyframes, options)
    );
    animation.play();
    return animation;
  };

  #slideAnimation = (scrollStart, scrollEnd) =>
    this._animate(scrollStart, scrollEnd, 500);

  #canGoLeft = () => {
    return this._lastMove > 0
  };

  #canGoRight = () => {
    return this._currentSlideIndex > 0;
  };
  
  #scroll = (direction) => {
    if (this._isScrolling) return;

    this._isScrolling = true;
    let slideAnimation;

    this._lastMove = this._calculateLastMove();

    switch (direction) {
      case 'left':
        if (this.#canGoLeft()) {
          this._scrollFactor = this._calculateScrollFactorToLeft();

          // calculate the last slide length and move only for that amount of pixels
          this._scrollMoveEnd -= this._scrollFactor;

          slideAnimation = this.#slideAnimation(
            this._scrollMoveEnd + this._scrollFactor,
            this._scrollMoveEnd,
          );

          this._currentSlideIndex += 1;

          break;
        }
        break;
      case 'right':
        if (this.#canGoRight()) {
          this._scrollFactor = this._calculateScrollFactorToRight();

          this._scrollMoveEnd += this._scrollFactor;

          slideAnimation = this.#slideAnimation(
            this._scrollMoveEnd - this._scrollFactor,
            this._scrollMoveEnd,
          );

          this._currentSlideIndex -= 1;

          break;
        }
        break;
      default: void(0)
    }

    slideAnimation.onfinish = () => this._isScrolling = false;
  };

  #initScroll = () => {
    this.viewModel.scrollLeft.addEventListener('click', () =>
      this.#scroll('right'),
    );
    this.viewModel.scrollRight.addEventListener('click', () =>
      this.#scroll('left'),
    );
  };

  #syncActiveSlideWithIndicators = () => {
    const { blockTitles, getIndicatorDots} = this.viewModel;

    syncActiveSlideWithIndicators({
        targetElements: blockTitles,
        getIndicatorDots,
    });
  };

  refresh = () => {
    const { itemsContainer, singleItem } = this.viewModel;

    this.#slideAnimation(
      0,
      0,
    );

    this._currentSlideIndex = 0;
    this._scrollMoveEnd = 0;

    this._remainingScrollableWidth =
      itemsContainer.scrollWidth - itemsContainer.clientWidth;
    this._scrollFactor = singleItem.clientWidth + this._itemGap;

    this._refreshArrows();
    this.#syncActiveSlideWithIndicators();
  };

  init = () => {
    const { blockItems } = this.viewModel;

    if (blockItems.length <= 1) {
      return;
    }

    this.refresh();
    this.#initScroll();
  };
}

export default CarouselController;
