import { addScriptsToNextButtons } from './addScripts';

export class FormNextStepScripts {
    constructor() {
        let stepDiv = document.querySelectorAll('[data-step-scripts-index]');

        if (!stepDiv.length) {
            return null;
        }
        
        const formGuid = document.getElementById('FormGuid').value;

        let nextStepButton = document.querySelector('button[value="NextStep"]');
        let currentStepDiv = document.querySelector('.Form__NavigationBar__ProgressBar__CurrentStep');
        let totalSteps = document.querySelector('.Form__NavigationBar__ProgressBar__StepsCount').innerHTML;

        let processedSteps = [1, parseInt(totalSteps)]; // steps that should not be processed; initiate with first and last step
      
        var xhr = new XMLHttpRequest();
        xhr.open('GET', `customformcontainerblock/getnextstepscripts?formguid=${formGuid}`);
        xhr.onreadystatechange = function () {            
            if (xhr.readyState === 4 && xhr.status === 200) {
                if (xhr.responseText != "") {
                    let allScripts = JSON.parse(xhr.responseText);                    

                    // start with step 1, which always exists if this piece of code is executed        
                    addScriptsToNextButtons(1, nextStepButton, allScripts);

                    // observer for changes in the current step (these changes coming from Epi's scripts)
                    let observer = new MutationObserver(() => {
                        // fire only if the current step has not been processed already
                        if (!processedSteps.includes(parseInt(currentStepDiv.innerHTML))) {
                            addScriptsToNextButtons(currentStepDiv.innerHTML, nextStepButton, allScripts)
                            processedSteps.push(parseInt(currentStepDiv.innerHTML)); // add the step so we don't process it again
                        }
                    })

                    observer.observe(currentStepDiv, {childList: true})
                }
            }
        };        

        xhr.send();        
    }
}