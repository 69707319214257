import { IsMobile } from '../isMobile/index';

export class AutoplayDesktopVideo {
  static init() {
    if (!IsMobile.check()) {
      var videos = document.querySelectorAll('video.video-background');
      videos.forEach((video) => {
        video.setAttribute('autoplay', 'autoplay');
        video.play();
      });
    }
  }
}
