﻿export class BackToTopButton {
  constructor() {
    const backToTopContainer = document.querySelector('[data-target="backToTop"]');
    const backToTopButton = document.querySelector('#backToTopButton');

    window.addEventListener('scroll', function() {
      const scrollPosition = window.scrollY;
      if (scrollPosition >= 300) {
        backToTopContainer.classList.remove('hidden');
      } else {
        backToTopContainer.classList.add('hidden');
      }
    });

    backToTopButton.addEventListener('click', function(e) {
      e.preventDefault();

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  }
}
