import Constants from './mobilemenuConstants';
import { IsMobile } from "../common/isMobile";
import { ensureHeights, ensureScrollable, setMenuHeight, setElementTranslation, hideSearch, showSearchIcon, showcountryIcon, heights, getSiblings, expandMenuItem, colapseMenuItem } from './mobilemenuFunctions';
import { slideMenu } from "../slide-menu";

export class MobileMenu {
    static init() {
        const menu = document.querySelector('#my-menu');
        const menuTogglers = document.querySelectorAll('.js-slide-menu-trigger');

        slideMenu(menu, menuTogglers);

        ensureHeights();

        //open mobile menu
        Constants.menuTrigger?.addEventListener('click', () => {
            //hide search on any click on menu
            hideSearch();

            if(Constants.menuTrigger.classList.contains('clicked')) {
                //when 'clicked' - menu icon transforms into X which needs to close the currently open menu
                Constants.menuTrigger.classList.remove('clicked');
                Constants.body.classList.remove('overflow-hidden');
                //by opening the conuntry menu, menuTrigger (burger icon) transforms into close button, which needs to close the country menu without opening the main navigation
                Constants.menuNav.classList.remove('open');
                Constants.countryNav.classList.remove('open');
                //by closing the conuntry menu, globe/flag and search icon are shown again
                showcountryIcon();
                showSearchIcon();

                ensureHeights();
                //close all menus
                Constants.menuNav.style.transform = 'translateY(-100%)';
                Constants.countryNav.style.transform = 'translateY(-100%)';
            } else {
                //when 'clicked' - menu icon transforms into X which needs to close the currently open menu
                Constants.menuTrigger.classList.add('clicked');
                Constants.menuNav.classList.add('open');
                //stop background scroll when menu is open
                Constants.body.classList.add('overflow-hidden');
                //include all elements heights because of calculations with visible crisis modal and repositioning the menu
                ensureHeights();

                //main nav needs to be moved lower when search is triggered while the menu is open
                if (Constants.search.classList.contains('active')) {
                    setElementTranslation(Constants.menuNav, heights.searchHeight);

                    if (Constants.crisisModal) {
                        setElementTranslation(Constants.menuNav, heights.crisisModalWithSearchHeight);
                    }
                } else {
                    Constants.menuNav.style.transform = 'translateY(0)';
                    if (Constants.crisisModal) {
                        setElementTranslation(Constants.menuNav, heights.crisisModalWithSearchHeight);
                    }
                }

                if(Constants.getMainNavigationBreadcrumbs()) {
                    setElementTranslation(Constants.menuNav, heights.breadcrumbsHeight)
                    if (Constants.crisisModal) {
                        setElementTranslation(Constants.menuNav, heights.crisisModalWithBreadcrumbsHeight)
                    }
                }

                ensureScrollable();
            }
        });

        //open country menu
        Constants.countryTrigger?.addEventListener('click', () => {
            ensureHeights();
            if (Constants.countryNav.classList.contains('open')) {
                Constants.countryNav.classList.remove('open');
                Constants.body.classList.remove('overflow-hidden');
                showSearchIcon();
                //'clicked' transforms menu icon into close button
                Constants.menuTrigger.classList.remove('clicked');
            } else {
                //by opening the country menu, globe/flag and search icon are not shown
                Constants.countryTrigger.style.display = 'none';
                Constants.searchTrigger && (Constants.searchTrigger.style.display = 'none');
                hideSearch();
                Constants.countryNav.classList.add('open');
                //stop background scroll when menu is open
                Constants.body.classList.add('overflow-hidden');
                Constants.menuTrigger.classList.add('clicked');
                Constants.countryNav.style.transform = 'translateY(0)';

                //menu repositioning if crisis modal is visible
                if (Constants.crisisModal) {
                    setElementTranslation(Constants.countryNav, heights.crisisModalHeight)
                }

                //menu repositioning if breadcrumbs are visible (only in case of main navigation)
                if(Constants.getMainNavigationBreadcrumbs()) {
                    setElementTranslation(Constants.countryNav, heights.breadcrumbsHeight)

                    if (Constants.crisisModal) {
                        setElementTranslation(Constants.countryNav, heights.crisisModalWithBreadcrumbsHeight)
                    }
                }
            }

            ensureScrollable();
        });

        // open menu accordion levels and sublevels
        Constants.subitemsTrigger.forEach(item => item.addEventListener('click', (event) => {
            const { target } = event;

            const menuItem = target.closest(`.${Constants.menuItemClassName}`);
            const menuItemSiblings = getSiblings(menuItem)
                .filter((i) => i.classList.contains(Constants.menuItemClassName));

            menuItemSiblings.forEach(colapseMenuItem);

            if(menuItem.classList.contains('open')) {
                colapseMenuItem(menuItem);
            } else {
                expandMenuItem(menuItem);
            }
        }));

        //open search
        Constants.searchTrigger?.addEventListener('click', () => {
            if (Constants.search.classList.contains('active')) {
                hideSearch();
                //include all elements heights because of calculations with visible crisis modal and repositioning the menu
                ensureHeights();

                //menu repositioning
                if(Constants.menuTrigger.classList.contains('clicked')) {
                    Constants.menuNav.style.transform = 'translateY(0)';
                    if (Constants.crisisModal) {
                        setElementTranslation(Constants.menuNav, heights.crisisModalHeight)
                    }
                    if(Constants.getMainNavigationBreadcrumbs()) {
                        setElementTranslation(Constants.menuNav, heights.breadcrumbsHeight)
                        if (Constants.crisisModal) {
                            const breadcrumbsWithCrisisHeight = heights.breadcrumbsHeight + heights.crisisModalHeight;
                            setElementTranslation(Constants.menuNav, breadcrumbsWithCrisisHeight)
                        }
                    }
                }
            } else {
                Constants.search.classList.add('active');
                Constants.searchInput.focus();
                //needs overflow because of full height when opening the menu while search is open
                Constants.mobileMenu.classList.add('overflow-hidden');
                //positioning banner lower when search is open
                Constants.bannerImages.forEach(image => image.classList.add('search-active'));

                // if breadcrumbs are shown, search gets their height as padding + the regular padding to be moved from the breadcrumbs instead of being sticked to them
                if(Constants.getMainNavigationBreadcrumbs()) {
                    let searchTopPadding = 20;
                    Constants.search.style.paddingTop = `${heights.breadcrumbsHeight + searchTopPadding}px`
                }

                //include all elements heights because of calculations with visible crisis modal and repositioning the menu
                ensureHeights();

                // Mobile menu dropdown items need to be moved lower when search is open
                if (Constants.menuNav.classList.contains('open')) {
                    setElementTranslation(Constants.menuNav, heights.searchHeight)

                    if (Constants.crisisModal) {
                        setElementTranslation(Constants.menuNav, heights.crisisModalWithSearchHeight)
                    }

                    if(Constants.getMainNavigationBreadcrumbs()) {
                        setElementTranslation(Constants.menuNav, heights.searchHeight)
                        if (Constants.crisisModal) {
                            setElementTranslation(Constants.menuNav, heights.crisisModalWithSearchHeight)
                        }
                    }
                }

            }
        });

        //activate 2nd level accordion menu on mobile
        if(Constants.submenuTrigger) {
            Constants.submenuTrigger.addEventListener('click', () => {
                if(Constants.submenuContainer.classList.contains('open')) {
                    Constants.submenuContainer.classList.remove('open')
                } else {
                    Constants.submenuContainer.classList.add('open')
                }
            });
        }

        //if crisis modal is closed, close all menus to reset the translationed positions
        Constants.crisisModalCloseBtn?.addEventListener('click', () => {
            Constants.menus.forEach(menu => {
                menu.classList.remove('open');
                menu.style.transform = 'translateY(-100%)';
            })

            Constants.mobileMenu.style.marginTop = 0;

            Constants.menuNav?.classList.remove('open');
            Constants.menuTrigger?.classList.remove('clicked');
            showcountryIcon();
            showSearchIcon();
            heights.crisisModalHeight = 0; // YACC-2413 fix, reset height when crisis modal not visible
            hideSearch()

            //if no crisis modal - make menu height 100vh - necessary for iphones
            setMenuHeight(100);
        });

        if (Constants.menuNav && Constants.crisisModal) {
            const crisisModalHeight = Constants.crisisModal.offsetHeight;
            Constants.mobileMenu.style.marginTop = `${crisisModalHeight}px`;

            window.addEventListener('resize', () => {
                const crisisModalHeight = Constants.crisisModal.offsetHeight;
                Constants.mobileMenu.style.marginTop = `${crisisModalHeight}px`;

                if(Constants.getMainNavigationBreadcrumbs()) {
                   document.querySelector('.breadcrumbs-container').style.top =  `${crisisModalHeight + Constants.headerInner?.offsetHeight}px`;
                }
            })
        }

        if (Constants.dropdownMobileMenu && Constants.crisisModal) {
            const crisisModalHeight = Constants.crisisModal.offsetHeight;
            Constants.dropdownMobileMenu.style.top = `${crisisModalHeight}px`;

            window.addEventListener('resize', () => {
                const crisisModalHeight = Constants.crisisModal.offsetHeight;
                Constants.dropdownMobileMenu.style.top = `${crisisModalHeight}px`;
                menu.style.top = `${crisisModalHeight}px`;
            })
        }

        if (Constants.mmHeaderContainer && Constants.crisisModal) {
            // desktop header
            const logoTopPosition = 30;

            window.addEventListener('resize', () => {
                const crisisModalHeight = Constants.crisisModal.offsetHeight;
                Constants.logo.style.top = `${logoTopPosition + crisisModalHeight}px`;

                if (Constants.desktopBannerImage) {
                    Constants.desktopBannerImage.style.top = `${crisisModalHeight}px`;
                }

                Constants.mmHeaderContainer.style.paddingTop = `${crisisModalHeight}px`;

                if (IsMobile.checkMobileAndTabletScreen() && !this.desktopBannerImage) {
                    Constants.mmHeaderContainer.style.paddingTop = 0;
                }
            });
        }
    }
}
