﻿export class PrintButton {
    constructor() {
        // Div element to be printed must use the data-print-div attribute with no value
        // For the print button, it must have the data-print-button attribute
        const print = {
            el: document.querySelectorAll("[data-print-div]"),
            btn: document.querySelectorAll("[data-print-button]"),
            styles: document.querySelectorAll("[rel='stylesheet']")
		}

        //function to convert turf charts into images for printing and back to charts after the printing
        function turfChartsPrint(shouldRemoveImages) {
            const turfCharts = document.querySelectorAll('.chart');
            const imagesToDelete = document.querySelectorAll('.delete-after-printing');
            
            if (!shouldRemoveImages) {
                for (let i = 0; i < turfCharts.length; i++) {
                    let image = document.createElement('img');
                    let imageSrc = turfCharts[i].toDataURL();
                    image.setAttribute('class', 'delete-after-printing');
                    image.src = imageSrc;
                    turfCharts[i].style.display = 'none';
                    turfCharts[i].parentNode.insertBefore(image, turfCharts[i]);                
                }
            }
            
            if (shouldRemoveImages) {
                // using the same loop for images and charts, as the length will always be the same for both
                for (let i = 0; i < imagesToDelete.length; i++) {
                    imagesToDelete[i].parentNode.removeChild(imagesToDelete[i]);
                    turfCharts[i].style.display = 'block';
                }
            }
        }

		//removing all calculator block logo images when print is called from window
		window.addEventListener("beforeprint", function() {
			var logos = document.querySelectorAll(".logo--print");
			for(var i=0;i<logos.length;i++){
				logos[i].style.display = 'none';
				logos[i].style.height = '0';
				logos[i].style.opacity = '0';
			}
		});
		//resetting all calculator block logo images after window.print() is done, because logo images are needed for click button print
		window.addEventListener("afterprint", function() { 
			var logos = document.querySelectorAll(".logo--print");
			for(var i=0;i<logos.length;i++){
				logos[i].style.display = 'block';
				logos[i].style.height = 'auto';
				logos[i].style.opacity = '1';
			}
		});

        document.addEventListener("DOMContentLoaded", function () {

            // Loop through divs that are printable
            for (let i = 0; i < print.el.length; i++) {
                (function () {
                    const k = i;

                    // On print button click
                    print.btn[k].addEventListener("click",
                        function () {
							//IE11 does not support svg clip-path from Logo.cshtml so gives "undefined" - this is removed when button clicked print is called
							var logo = document.querySelectorAll("#Layer_1");
							var logoSvgClone;
							var logoParent;
							//svg element cloned because of reverting it after print is closed
							if(logo.length > 0){
								logoSvgClone = logo[0].cloneNode(true);
								logoParent = logo[0].parentNode;
								logoParent.removeChild(logo[0]);
                            }
                            
                            turfChartsPrint(false);

                            // Opens div in another window to isolate elements
                            const printWindow = window.open("", "PRINT", "height=700,width=1200");

                            const styles = document.querySelectorAll("style, [rel='stylesheet']");
                            const inputs = print.el[k].querySelectorAll("input");

                            // Detect if user is using Chrome
                            const isChrome = Boolean(printWindow.chrome);

                            // Create array for input values
                            let inputValues = [];

                            // Get all internal and external styles which will be applied to print window
                            let css = "";
                            for (let n = 0; n < styles.length; n++) {
                                if (styles[n].outerHTML)
                                    css += styles[n].outerHTML;
                            }

                            // Loop through inputs and push values into an array
                            for (var item in inputs) {
                                if (Object.prototype.hasOwnProperty.call(inputs,item)) {
                                    inputValues.push(inputs[item].value);
                                }
                            }

                            // Generate and populate print window
                            printWindow.document.write("<html><head><title>" + document.title + "</title>");
                            printWindow.document.write(css);
                            printWindow.document.write("</head><body>");

                            // Get parent element of the div to be printed
                            let outerHtml = print.el[k].parentNode.outerHTML;
                            printWindow.document.write(outerHtml);

                            // Selectors that are available after writing div
                            const targets = printWindow.document.querySelectorAll("input");
                            const noprint = printWindow.document.querySelectorAll(".noprint");

                            printWindow.document.write("</body></html>");

							// Remove elements that have a noprint class
							if (noprint.length) {
								for (let e = 0; e < noprint.length; e++) {
									//.remove() does not work in IE 11
									noprint[e].parentNode.removeChild(noprint[e]);
								}
							}

                            // Set input values from array to input fields in the print window
                            // Set input fields to readonly so users can't manipulate data in print window
                            for (let a = 0; a < inputValues.length; a++) {
                                if (targets[a] !== undefined) {
                                    targets[a].setAttribute("value", inputValues[a]);
                                    targets[a].setAttribute("readonly", "");
                                }
                            }

                            // Finish writing the print window document
                            printWindow.document.close();

                            // Focus on print window
                            printWindow.focus();

                            // There is a bug in chrome that prints the window before the styles are set
                            if (isChrome) {
                                printWindow.onload = function() {
									printWindow.print();
								}
								//Edge does not support .onload, but loads under "isChrome" without activating the print preview
								setTimeout(function(){
									printWindow.print();
                                }, 150);
                            } else if (navigator.userAgent.includes('Firefox') && document.querySelectorAll("[data-target='#turf-calculator']").length) {
                                printWindow.onload = function() {
									printWindow.print();
								}
							} else {
								printWindow.print();
							}
							
							//reverting the svg logo after print is done
							if (logoSvgClone && logoParent){
								logoParent.appendChild(logoSvgClone);
                            }
                            
                            turfChartsPrint(true);

							// The print window can be closed with the code below, but Safari has some bugs where it closes the window before printing.
							// printWindow.close();

                            return false;
                        }
                    );
                }());
            }
        });
    }
}