if (typeof $$epiforms !== 'undefined') {
    (function ($) {

        $.extend(true, epi.EPiServer.Forms, {
            Validators: {
                "Opti.Public.Websites.Features.Blocks.CustomElementBlocks.Validators.VerticalChoicesClientValidator": function (fieldName, fieldValue, validatorMetaData) {

                    var message = validatorMetaData.model.message;
                    var maxItems = validatorMetaData.model.additionalAttributes.maxItems;

                    if (maxItems != "0" && fieldValue.length > maxItems) {
                        return { isValid: false, message: message };
                    }

                    return { isValid: true };
                },
            },
        });
    })($$epiforms || $);
}