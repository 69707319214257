const CarouselViewModel = (blockElement) => {
  const carouselItemsSelector = '[data-carousel-slides]';
  const carouselSingleItemSelector = '[data-carousel-slide-item]';
  const carouselTitlesSelector = `[data-carousel-slide-title]`;
  const carouselIndicatorsContainerSelector = '[data-carousel-indicators]';
  const carouselIndicatorDotsSelector = '[data-carousel-indicator-dot]';
  const carouselArrows = '[data-carousel-arrows]';
  const carouselHeading = '.carousel-block__heading';
  const scrollLeftSelector = '[data-scroll-left]';
  const scrollRightSelector = '[data-scroll-right]';

  const itemsContainer = blockElement.querySelector(carouselItemsSelector);

  const viewModel = {
    carouselBlock: blockElement,
    itemsContainer,
    blockItems: blockElement.querySelectorAll(carouselSingleItemSelector),
    singleItem: blockElement.querySelector(carouselSingleItemSelector),
    scrollLeft: blockElement.querySelector(scrollLeftSelector),
    scrollRight: blockElement.querySelector(scrollRightSelector),
    arrows: blockElement.querySelector(carouselArrows),
    scrollTolerance:
      window.getComputedStyle(itemsContainer).getPropertyValue('margin-left') ||
      '0',
    blockTitles: [...blockElement.querySelectorAll(carouselTitlesSelector)],
    indicatorsContainer: blockElement.querySelector(carouselIndicatorsContainerSelector),
    carouselHeading: blockElement.querySelector(carouselHeading),
    getIndicatorDots: () => {
      return blockElement.querySelectorAll(carouselIndicatorDotsSelector)
    },
    getBlockContainerWidth: () => {
      return blockElement.parentElement.clientWidth;
    },
    isDesktopScreenAndBigger: () => window.innerWidth >= 1200,
  };
  return viewModel;
};

export default CarouselViewModel;
