export class YaraFormSteps {
  constructor() {
    let yaraSteps = document.querySelectorAll("[data-form-with-yara-steps]");

    if (typeof $$epiforms === "undefined" || !yaraSteps.length) {
      return null;
    }

    $$epiforms(".EPiServerForms").on("formsSetupCompleted", function (event) {
      let currentFormDom = event.currentTarget;
      let workingFormInfo = event.workingFormInfo;

      let previousButton = currentFormDom.querySelectorAll(
        "[data-previous-button]"
      )[0];
      const getNextButton = () =>
        currentFormDom.querySelectorAll("[data-next-button]")[0];
      let submitButton = currentFormDom.querySelector(".FormSubmitButton");
      if (getSubmitCloneBtn(getNextButton()) !== undefined) {
        initSubmitCloneButton(submitButton, getSubmitCloneBtn(getNextButton()));
      }
      // initial update
      updateButtons(
        previousButton,
        getNextButton(),
        yaraSteps,
        workingFormInfo,
        false,
        false
      );

      previousButton.addEventListener("click", function () {
        updateButtons(
          previousButton,
          getNextButton(),
          yaraSteps,
          workingFormInfo,
          true,
          false
        );
      });
      getNextButton().addEventListener("click", function () {
        updateButtons(
          previousButton,
          getNextButton(),
          yaraSteps,
          workingFormInfo,
          false,
          true
        );
      });
    });

    const stylesClassesToRemove = [
      "btn",
      "btn--primary",
      "btn--secondary",
      "underlined-link",
      "left",
      "right",
      "center",
    ];
    const removeStyles = (arrStylesToRemove, element) =>
      element.classList.remove(...arrStylesToRemove);
    const getSubmitCloneBtn = (nextBtnElement) =>
      nextBtnElement.parentElement.parentElement.querySelectorAll(
        "[data-submit-button]"
      )[0];
    const initSubmitCloneButton = (submitOriginalButton, submitCloneButton) =>
      submitCloneButton.addEventListener("click", () =>
        submitOriginalButton.click()
      );

    const hideDisplayNextSubmit = (
      currentStep,
      nextButton,
      submitCloneButton
    ) => {
      if (currentStep.dataset.thisIsSubmit === "True") {
        nextButton.style.display = "none";
        submitCloneButton.style.display = "inline-block";

        // the following two lines needed to prevent formsubmitbuttonspinner to add spinner classes to the button before its time.
        submitCloneButton.classList.remove("button-loading-spinner");
        submitCloneButton.removeAttribute("disabled");

        document.querySelectorAll(
          ".submitbuttonelementblock"
        )[0].style.display = "none";
        updateButtonTextAndStyle(currentStep, submitCloneButton);
      } else {
        nextButton.style.display = "inline-block";
        submitCloneButton.style.display = "none";
      }
    };

    const updateAlignement = (currentStep, previousButton) => {
      if (currentStep !== undefined) {
        let buttonAlignment = currentStep.dataset.buttonAlignment;
        previousButton.parentElement.classList.add(buttonAlignment);
      }
    };

    const updateButtonTextAndStyle = (currentStep, buttonToUpdate) => {
      if (currentStep !== undefined) {
        let hidePreviousButton = currentStep.dataset.hidePreviousButton;
        let previousButtonStyles =
          currentStep.dataset.previousButtonStyle.split(" ");
        let nextButtonStyles = currentStep.dataset.nextButtonStyle.split(" ");

        if (buttonToUpdate.dataset.previousButton !== undefined) {
          [...previousButtonStyles].forEach((style) => {
            buttonToUpdate.classList.add(style);
          });
          buttonToUpdate.innerText = currentStep.dataset.previousButtonText;
          buttonToUpdate.style.display =
            hidePreviousButton === "True" ? "none" : "inline-block";
        } else {
          [...nextButtonStyles].forEach((style) => {
            buttonToUpdate.classList.add(style);
          });
          buttonToUpdate.innerText = currentStep.dataset.nextButtonText;
        }
      }
    };

    const updateButtons = (
      previousBtn,
      nextBtn,
      yaraSteps,
      workingFormInfo,
      previous,
      next
    ) => {
      // removes styles to reset in case styles changes between one step and another
      const elementsToResetStyle = [
        previousBtn,
        nextBtn,
        previousBtn.parentElement,
        getSubmitCloneBtn(nextBtn),
      ];
      elementsToResetStyle.forEach((element) =>
        removeStyles(stylesClassesToRemove, element)
      );

      let currentStepIndex =
        epi.EPiServer.Forms.Utils.getCurrentStepIndex(workingFormInfo);

      let currentYaraStep = yaraSteps[0];

      if (previous) {
        currentYaraStep = yaraSteps[currentStepIndex];
      }

      if (next) {
        currentYaraStep =
          currentStepIndex === workingFormInfo.StepsInfo.Steps.length - 1
            ? yaraSteps[currentStepIndex]
            : yaraSteps[currentStepIndex + 1];
      }

      updateAlignement(currentYaraStep, previousBtn);
      [previousBtn, nextBtn].forEach((btn) =>
        updateButtonTextAndStyle(currentYaraStep, btn)
      );

      if (getSubmitCloneBtn(nextBtn) !== undefined) {
        hideDisplayNextSubmit(
          currentYaraStep,
          nextBtn,
          getSubmitCloneBtn(nextBtn)
        );
      }
    };
  }
}
