$('html').removeClass('no-js');

/* Import all modules here */
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
// Common Components
import { AnchorScroll } from './components/common/anchor-scroll/index';
import { YoutubePlayer } from './components/common/youtube/index';
import { ContentCollapse } from './components/common/content-collapse/index';
import { LazyLoad } from './components/common/lazyload/index';
import { SelectricDropdown } from './components/common/selectric/index';
import { InputNumberAdvanced } from './components/common/input-number-advanced/input-number-advanced';
import { Tables } from './components/common/tables/index';
import './components/common/helpers/textHelpers';
import { AutoplayDesktopVideo } from './components/common/autoplay-desktop-video';
import './components/common/animate/animate';

import './components/formvalidators/index';
import './components/formvalidators/verticalchoicesvalidator';
import { TableBehavior } from './components/table/index';
import { MobileMenu } from './components/mobilemenu/index';
import { CountryPicker } from './components/countrypicker/index';
import SearchFilter from './components/search/index';
import { DropdownMenu } from './components/dropdown/index';
import { MegaMenuDropdown } from './components/megamenudropdown/index';
import { ContactCard } from './components/contactcard/index';
import { SelectLink } from './components/selectlink/index';
import { Carousel } from './components/carousel/index';
import { ImageCaption } from './components/imagecaption/index';
import { MobileSubMenu } from './components/mobilesubmenu/index';
import { ArchiveWebcast } from './components/archivewebcast/index';
import { SectionIntro } from './components/sectionintro/index';
import { IconBlock } from './components/iconblock/index';
import { Tabs } from './components/tabs/index';
import { PrintButton } from './components/printbutton/index';
import { BackToTopButton } from './components/backtotopbutton';
import { ExternalLinkModal } from './components/externallinkmodal';
import { VideoReload } from './components/videoreload';
import { VideoBlock } from './components/videoblock';
import { IframeResize } from './components/iframeresize';
import { FormSubmitButtonSpinner } from './components/formsubmitbuttonspinner/index';
import { HideEmails } from './components/hideemails';
import { FormsInstantValidation } from './components/formsinstantvalidation';
import { FormNextStepScripts } from './components/formnextstepscripts';
import { FormsCustomDependency } from './components/formscustomdependency';
import { HeaderExpandableSearch } from './components/headerexpandablesearch/headerExpandableSearch';
import { YaraFormSteps } from './components/yaraformsteps';
import './components/cookies/cookieInformationScripts';
import './components/cookies/tinyMCEIframesForCI';
import { FormsSendToTealium } from './components/formssendtotealium';
import './components/cookies/imagesLoadingBehindGDPRBanner';
import './components/carouselblock/carouselBlock';
import './components/carouselblock/imageGalleryCarouselBlock';
import './components/mainnav/mobileNavigation';
import './components/mainnav/breadcrumbs';
import './components/skiptomaincontent';

/* Create all class Instances here */
const classes =  [
AnchorScroll,
 SelectLink,
 ImageCaption,
 ContactCard,
 MobileSubMenu,
 SearchFilter,
 IconBlock,
 SectionIntro,
 Tabs,
 ExternalLinkModal,
 BackToTopButton,
 VideoReload,
 VideoBlock,
 PrintButton,
 IframeResize,
 TableBehavior,
 InputNumberAdvanced,
 FormSubmitButtonSpinner,
 Tables,
 HideEmails,
 FormsInstantValidation,
 FormNextStepScripts,
 FormsCustomDependency,
 YaraFormSteps,
 FormsSendToTealium
];

classes.forEach(Class => { new Class(); });

/* Initiate all modules here */
const components = [
    YoutubePlayer,
    MobileMenu,
    DropdownMenu,
    MegaMenuDropdown,
    ContentCollapse,
    CountryPicker,
    Carousel,
    LazyLoad,
    SelectricDropdown,
    HeaderExpandableSearch,
    AutoplayDesktopVideo,
    ArchiveWebcast
];

components.forEach(component => { component.init(); });