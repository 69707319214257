export class HeaderExpandableSearch {
    static init() {
        const expandableSearchArray = document.querySelectorAll('#header-expandable-search');

        if(expandableSearchArray.length) {
            
            const mainNavigation = document.querySelector('[data-navigation="main-navigation"]');
            const expandableSearch = expandableSearchArray[0];
            const expandSearchArr = expandableSearch.querySelectorAll('[data-target="expand-search"]');
            const collapseSearchArr = expandableSearch.querySelectorAll('[data-target="collapse-search"]');
            const triggerSearchArr = expandableSearch.querySelectorAll('[data-target="trigger-search"]');
            const searchInputContainer = expandableSearch.querySelector('.search-container');
            const menuLinksParent = mainNavigation.querySelector('ul');
            const menuLinks = [...mainNavigation.querySelectorAll('li:not(#header-expandable-search)')];
            const searchInput = searchInputContainer.querySelector('input');
            const smallRatioViewportMenu = window.innerWidth / menuLinksParent.getBoundingClientRect().width <= 2.0;
            let lockTransition = false;

            const triggerSearch = ( searchUrl, searchQuery) => window.location.href = `${searchUrl}?q=${searchQuery}`;
            const fastHide = (listOfElems, amountElentsToHide) => listOfElems.map( (link, i) => i < amountElentsToHide ? link.setAttribute("style", "display:none;") : null );
            const slowDisplay = (listOfElems, amountElentsToHide, time) => window.setTimeout( () => { listOfElems.map( (link, i) => i < amountElentsToHide ?  link.setAttribute("style", "display:list-item;") : null ) } , time);
            const toggleSearchText = (displayProp, time ) => {
                const navSearchText = document.getElementById('nav-search-text');
                if (navSearchText !== null) {
                    window.setTimeout(() => { navSearchText.setAttribute("style", `display:${displayProp};`) }, time);
                    navSearchText.classList.remove("hidden");
                }
            }
            // ensure all settimeout from expandSearchInput() are executed before enabling opposite functions
            const collapseSearchInputCheck = () => lockTransition ? window.setTimeout(() => collapseSearchInput(), 600): collapseSearchInput() ;

            const expandSearchInput = () => {
                lockTransition = true;
                toggleSearchText('hidden', 200);
                // when many long nav items, hide quickly first nav item to avoid wrapping
                if (smallRatioViewportMenu) fastHide(menuLinks, 2 )

                window.setTimeout(() => { menuLinks.map( menu => menu.setAttribute("style", "display:none;") ), lockTransition = false }, 600);
                menuLinks.map( menu => menu.classList.add("hidden") );
                searchInputContainer.classList.add("open");
                expandableSearch.classList.add("open");
                searchInput.focus();
                listenBlurFromSearch();
            }

            const collapseSearchInput = () => {
                toggleSearchText('block', 200);
                menuLinks.map( menu => menu.classList.remove("hidden") );
                window.setTimeout(() => { menuLinks.map( menu => menu.setAttribute("style", "display:flex;") ) }, 200);
                if (smallRatioViewportMenu) slowDisplay(menuLinks, 2, 500)
                searchInputContainer.classList.remove("open");
                expandableSearch.classList.remove("open");
                removeListenBlur();
            }

            // eventslisteners for buttons
            [...expandSearchArr].forEach( button => button.addEventListener('click', expandSearchInput ));
            [...collapseSearchArr].forEach( button => button.addEventListener('click', collapseSearchInputCheck ));
            [...triggerSearchArr].forEach( button => button.addEventListener('click', () => triggerSearch(document.getElementById('UrlSearch').value, searchInput.value) ));
            // expand on tab navigation
            searchInput.addEventListener('focus', expandSearchInput);

            // collapse for click outside & tab outside or escape (& remove eventlisteners)
            const clickCheckCollapse = (event) => expandableSearch.contains(event.target) ? null : collapseSearchInputCheck() ;
            const checkKeyCollapse = (event) =>  event.code === 'Escape' || event.code === 'Tab' ? collapseSearchInputCheck() : null;
            const listenBlurFromSearch = () => {
                document.addEventListener('click', clickCheckCollapse);
                document.addEventListener('keydown', checkKeyCollapse);
            }
            const removeListenBlur = () => {
                document.removeEventListener('click', clickCheckCollapse);
                document.removeEventListener('keydown', checkKeyCollapse);
            }
        }
        

    }
}
