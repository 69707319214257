const breadcrumbItems = document.querySelectorAll('[data-breadcrumb-items]');
const breadcrumbDots = document.querySelector('[data-breadcrumb-dots]');

let isBreadcrumbLong = false;

const toggleShowAllBreadcrumbItems = () => {
    if(isBreadcrumbLong) {
        breadcrumbItems.forEach(item => item.classList.add('breadcrumb-item--toggleable'))
        isBreadcrumbLong = false
        return
    }

    breadcrumbItems.forEach(item => item.classList.remove('breadcrumb-item--toggleable'))
    isBreadcrumbLong = true
}

if(breadcrumbItems.length > 0) {
    breadcrumbDots.addEventListener('click', function() {
        toggleShowAllBreadcrumbItems()
    })
}