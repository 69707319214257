export const addSiblingsToOrphanWordElmt = (elmt) => {
    let n = [], r = []
    n = elmt.length ? elmt : n.concat(elmt), Array.prototype.map.call(n, function (e) {
        let n = String(e.innerHTML)
        n = n.replace(/\s+/g, " ").replace(/^\s|\s$/g, ""), r.push(n ? e.innerHTML = n.replace(new RegExp("((?:[^ ]* ){" + ((n.match(/\s/g) || 0).length - 1) + "}[^ ]*) "), "$1&nbsp;") : void 0)
    })
}

function findElmtsToAvoidOrphanWords() {
    const noOrphanWordElmts = document.getElementsByClassName('no-orphan-word');
    if (noOrphanWordElmts.length)  [...noOrphanWordElmts].forEach(elmt => addSiblingsToOrphanWordElmt(elmt));
}

document.addEventListener('readystatechange', () => findElmtsToAvoidOrphanWords());

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
