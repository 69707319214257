export class HideEmails{
    constructor() {
        let emailLinks = document.querySelectorAll('a[href^="mailto:"]');

        if (!emailLinks.length) {
            return false;
        }

        [...emailLinks].forEach(emailLink => {
            // remove mailto: and the ___E!___ flag from the href
            let preText = 'mailto:___E!___';

            let isEncrypted = emailLink.href.startsWith(preText);

            let encryptedEmail = emailLink.href.slice(preText.length); 
            let hasSubject = emailLink.href.indexOf('?subject=') != -1;
            let subject;

            if (hasSubject) {
                let splitEmailAndSubject = encryptedEmail.split('?subject=');
                encryptedEmail = splitEmailAndSubject[0];
                subject = splitEmailAndSubject[1];
            }
            
            if (isEncrypted) {
                emailLink.addEventListener('click', ev => {
                    ev.preventDefault();               
                    window.location.href = !hasSubject ? `mailto:${decryptEmail(encryptedEmail)}` : `mailto:${decryptEmail(encryptedEmail)}?subject=${subject}`;
                });
                
                emailLink.addEventListener('touchstart', () => {
                    emailLink.href = !hasSubject ? `mailto:${decryptEmail(encryptedEmail)}` : `mailto:${decryptEmail(encryptedEmail)}?subject=${subject}`;
                });
            }
        });

        function decryptEmail(inputEmail) {
             let decryptedEmail = '';
             let character;
             
             for (let i=0; i<inputEmail.length; i++) {
                 switch (inputEmail[i]) {
                     case 'a':
                         character = 'z';
                         break;
                    case '@':
                        character = '@';
                        break;
                    case '.':
                        character = '.';
                        break;
                    case '_':
                        character = '_';
                        break;
                    case '-':
                        character = '-';
                        break;
                    default:
                        character = String.fromCharCode(inputEmail[i].charCodeAt(0) - 1);
                        break;
                }
                 decryptedEmail += character;
             }
             return decryptedEmail;
         }
    }
}