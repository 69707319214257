import Constants from './mobilemenuConstants';

let headerOuterHeight;
let mobileHeaderHeight;
let heightPercentage;

export const heights = {
  crisisModalHeight: 0,
  searchHeight: 0,
  crisisModalWithSearchHeight: 0,
  breadcrumbsHeight: 0,
  crisisModalWithBreadcrumbsHeight: 0,
};

export function ensureHeights() {
  // count in crisis modal only if shown
  if (Constants.crisisModal) {
    heights.crisisModalHeight = Constants.crisisModal.offsetHeight;
  }

  const navigationBreadcrumbs = Constants.getMainNavigationBreadcrumbs()
  // count in main navigation breadcrumbs only if shown
  if(navigationBreadcrumbs) heights.breadcrumbsHeight = navigationBreadcrumbs.offsetHeight;

  headerOuterHeight = Constants.mobileMenu?.offsetHeight;
  mobileHeaderHeight = Constants.headerInner?.offsetHeight;
  heights.searchHeight = headerOuterHeight - mobileHeaderHeight;
  heights.crisisModalWithSearchHeight = heights.crisisModalHeight + heights.searchHeight;
  heights.crisisModalWithBreadcrumbsHeight = heights.crisisModalHeight + heights.breadcrumbsHeight;
}

// ensure menu is not cut off and is scrollable when crisis modal is shown on different screen sizes
export function ensureScrollable() {
  if (Constants.crisisModal) {
    heightPercentage = Constants.crisisModal.offsetHeight * 100 / window.innerHeight;
    setMenuHeight(100 - heightPercentage);
  }
}

// if crisis modal is visible, change menu height to make it scrollable instead of cut off
export function setMenuHeight(height) {
  Constants.menus.forEach(menu => {
    menu.style.height = `${height  }%`;
    menu.style.minHeight = `${height  }vh`;
  })
}

export function setElementTranslation(element, value) {
  element.style.transform = `translateY(${value}px)`;
}

export function hideSearch() {
  if (!Constants.search) return;
  Constants.search.classList.remove('active');
  Constants.mobileMenu.classList.remove('overflow-hidden');
  // positioning banner back to top
  Constants.bannerImages.forEach(image => image.classList.remove('search-active'));
}

export function showSearchIcon() {
  Constants.searchTrigger && (Constants.searchTrigger.style.display = 'block');
}

export function showcountryIcon() {
  if(!Constants.countryTrigger) return;
  Constants.countryTrigger.style.display = 'block';
}

export function getSiblings(e) {
    // for collecting siblings
    let siblings = [];
    // if no parent, return no sibling
    if(!e.parentNode) {
        return siblings;
    }
    // first child of the parent node
    let sibling  = e.parentNode.firstChild;
    // collecting siblings
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== e) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
    }
    return siblings;
}

export function expandMenuItem(item) {
    item.classList.add('open');
    item.querySelector('.icon-plus').classList.add('active');
    item.querySelector('ul').style.display = 'block';
}

export function colapseMenuItem(item) {
    item.classList.remove('open');
    item.querySelector('.icon-plus').classList.remove('active');
    item.querySelector('ul').style.display = 'none';

    let subitems = item.querySelectorAll(`.${Constants.menuItemClassName}`);
    subitems.forEach(colapseMenuItem);
}