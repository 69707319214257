﻿import "hammerjs";

const modals = {};

export class Carousel {
	static init() {
		const FULL_SCREEN_SELECTOR = "[data-bs-target='#carousel-fullscreen']";

		const carousels = document.querySelectorAll(".carousel");
		let carouselItemHeight;
		let container;
		let controls;
		const mobile = window.matchMedia('screen and (max-width: 767px)').matches;

		if (!carousels.length) return;

		// Add height to outer container
		const applyHeight = function (height, container) {

			if (!height || !container)
				return false;

			// Add px if height argument is a number
			height = typeof height == "number" ? `${height}px` : height;

			if (mobile) {
				container.removeAttribute('style');
			} else {
				container.style.height = height;
			}
		};

		//positioning arrows on mobile
		const verticalAlign = function (controls, height) {
			controls?.forEach(control => {
				control.style.top = `${height / 2}px`;
			});
		};

		const getMaxHeight = function (carouselImages) {
			let maxHeight = carouselImages[0].clientHeight;
			const carouselItems = [...document.querySelectorAll('.carousel-item')];

			carouselItems.forEach((item) => {
				item.style.display = 'block';
				const image = item.querySelector("[data-bs-target='#carousel-image']");
				const optionHeight = image.clientHeight;
				maxHeight = Math.max(maxHeight, optionHeight);
				item.removeAttribute('style');
				});

			return maxHeight;
		};

		const handleFullScreenImage = (carousel) => (e) => {
			const toggle = e.currentTarget;
			const imageUrl = toggle.dataset.bsImageUrl;
			const imageAlt = toggle.dataset.bsImageAlt;
			const modalId = toggle.dataset.bsModalId;

			let modalImage = carousel.querySelector(modalId)?.querySelector(".carousel__modal-image");

			modalImage.setAttribute("src", imageUrl);
			modalImage.setAttribute("alt", imageAlt);

			// when an image is opened multiple times, multiple modal-backdrop elements appear but only one disappears. In case of forcing removal of all of them by clicking on close button - the 'overflow: hidden' style on body element will remain and can not be unset as 'new' modal is forcing it show up back again. This is the only reasonable way of fixing this.
			const modal = modals[modalId] || new bootstrap.Modal(carousel.querySelector(modalId));
			modal.show();
			modals[modalId] = modal;
		}

		window.addEventListener('load', function () {
			carousels.forEach(carousel => {
				// Enable swipe gestures
				const hammer = new Hammer(carousel);
				hammer.on("swiperight", () => {
					carousel.carousel("prev");
				});
				hammer.on("swipeleft", () => {
					carousel.carousel("next");
				});

				container = carousel.querySelector("[data-bs-target='#carousel-container']");
				controls = carousel.querySelectorAll("[data-bs-slide='prev'], [data-bs-slide='next']");
				let imageHeight = carousel.querySelector("[data-bs-target='#carousel-image']")?.clientHeight;

				const elements = container.querySelectorAll(".m-common-image");
				carouselItemHeight = getMaxHeight(elements);
				applyHeight(carouselItemHeight, container);
				verticalAlign(controls, imageHeight);

				// Carousel events
				carousel.addEventListener('slide.bs.carousel', (e) => {
					const carousel = e.currentTarget;
					const carouselItem = e.relatedTarget;
					const index = carouselItem.dataset.bsIndex;
					const controls = carousel.querySelectorAll("[data-bs-slide='prev'], [data-bs-slide='next']");
					const carouselCounter = carousel.querySelector("[data-bs-target='#carousel-counter']");
					const carouselImageHeight = carouselItem.querySelector("[data-bs-target='#carousel-image']")?.clientHeight;
					if(!carouselCounter) return;

					carouselCounter.textContent = parseInt(index) + 1;
					verticalAlign(controls, carouselImageHeight);

					// Toggle modal for images
					carouselItem.querySelector(FULL_SCREEN_SELECTOR)?.addEventListener("click", handleFullScreenImage(carousel));
				});

				carousel.querySelector(`.carousel-item.active ${FULL_SCREEN_SELECTOR}`)
						?.addEventListener("click", handleFullScreenImage(carousel));
			});
		});

		window.addEventListener('resize', () => {
			const carouselContainer = document.querySelector("[data-bs-target='#carousel-container']");
			const activeDiv = carouselContainer.querySelector(".active");
			const carouselImageHeight = activeDiv.querySelector("[data-bs-target='#carousel-image']")?.clientHeight;

			applyHeight(carouselItemHeight, carouselContainer);
			verticalAlign(controls, carouselImageHeight);
		});

	}
}