﻿export class ContactCard {
  constructor() {
    const contactCards = document.querySelectorAll(".js-o-contact-card");
    contactCards.forEach((contactCard) => {
      const mobile = contactCard.querySelector(".js-mobile")?.value;
      const telephone = contactCard.querySelector(".js-telephone")?.value;
      const telephoneText = contactCard.querySelector(
        ".js-secure-telephone-text"
      );
      const mobileText = contactCard.querySelector(".js-secure-mobile-text");
      let mobileRevealed = false;
      let telephoneRevealed = false;
      if (telephone) {
        telephoneText.addEventListener("click", (event) => {
          if (!telephoneRevealed) {
            event.preventDefault();
            telephoneRevealed = true;
          }
          telephoneText.textContent = telephone;
          telephoneText.parentElement.setAttribute("href", "tel:" + telephone);
        });
      }
      if (mobile) {
        mobileText.addEventListener("click", (event) => {
          if (!mobileRevealed) {
            event.preventDefault();
            mobileRevealed = true;
          }
          mobileText.textContent = mobile;
          mobileText.parentElement.setAttribute("href", "tel:" + mobile);
        });
      }
    });
  }
}
