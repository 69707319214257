// as there is a horizontal scroll on mobile view, this function is checking which slide is in focus and highlights its related indicator dot (also works for desktop indicators as well if any)

const syncActiveSlideWithIndicators = ({
    targetElements,
    getIndicatorDots,
}) => {
    const observerOptions = {
        rootMargin: '0px',
        threshold: 1,
    };

    const observerCallback = (entries) => {
        entries.forEach((entry) => {
            if (!entry.isIntersecting) return;

            const indicatorDots = getIndicatorDots();
            const activeTitleIndex = targetElements.findIndex((slide) => slide === entry.target);

            indicatorDots.forEach((indicator) => {
                indicator.classList.remove('active');
            });
            indicatorDots[activeTitleIndex].classList.add('active');
        });
    }

    const titleObserver = new IntersectionObserver(observerCallback, observerOptions);

    targetElements.forEach((target) => {
        if (!target) return;
        titleObserver.observe(target);
    });
};

export default syncActiveSlideWithIndicators;