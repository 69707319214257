import SelectElement from "./select.js";
export class SelectricDropdown {
  static init(dropdownContainer) {
    const selectContainers = dropdownContainer? [dropdownContainer] : document.querySelectorAll(".select-container:not(.dynamic-dropdown)");

    if (selectContainers.length) {
      [...selectContainers].forEach((selectContainer) => {
        const selectElement = selectContainer.querySelector(
          'select:not([id^="shadow-select"]):not(.ignore-selectric), datalist'
        );

        if(!selectElement) {
          return
        }

        const multiple = selectElement.multiple;
        const autocomplete = selectElement.hasAttribute("autocomplete");
        const options = {
          multiple: multiple ? { separator: "; " } : false,
          autocomplete: Boolean(autocomplete),
        };
        new SelectElement(selectContainer, options);
      });
    }
  }
}
