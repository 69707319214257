﻿export class AnchorScroll {
    constructor() {
        const anchorLinks = document.querySelectorAll("a[href^='#']");

        if (!anchorLinks.length) {
            return;
        }

        anchorLinks.forEach(link => {
            const tag = link.getAttribute('href').replace('#', '');
            const target = document.getElementById(tag);

            link.addEventListener('click', e => {
                e.preventDefault();

                if (target) {
                    scrollToTargetAdjusted(target, 45);
                }

                return false;
            });
        });

        const scrollToTargetAdjusted = (selector, offset) => {
            const elementPosition = selector.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - offset;

            const isCountryPicker = document.querySelector("[data-element='country-picker']").classList.contains('show');
            const animateElement = isCountryPicker ? document.querySelector('.modal') : document.documentElement;

            animateElement.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    }
}