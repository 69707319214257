export function slideMenu(menu, menuTogglers) {
    if(!menu && !menuTogglers.length) return;

    const menuItemLinks = menu?.querySelectorAll('[data-slide-menu-item-link]');

    const toggleMenu = () => {
        menu?.classList.toggle('slide-menu--open')
        document.body.classList.toggle('overflow-hidden')
    }

    const triggerSubmenu = (e) => {
        e.target.nextElementSibling.classList.add('open')
    }

    const closeSubmenu = (e) => {
        e.target.closest('[data-slide-menu-subitems]').classList.remove('open')
    }

    menuTogglers?.forEach(toggler => {
        const crisisModal = document.querySelector('[data-crisis-modal]');
        if(crisisModal && menu) {
            const crisisModalHeight = document.querySelector('.crisis-modal').offsetHeight;
            menu.style.top = `${crisisModalHeight}px`;
        }

        toggler.addEventListener('click', toggleMenu);
    })

    menuItemLinks?.forEach(link => {
        let ulElement = link.nextElementSibling;

        if(!ulElement?.children?.length) {
            return;
        }

        link.classList.add('has-children');

        link.addEventListener('click', (e) => {
            e.preventDefault()
            triggerSubmenu(e);
        });

        let linkTitle = link.textContent.trim()
        link.textContent = `${linkTitle}`

        let backLink = document.createElement('a')
        backLink.textContent = link.innerText;
        backLink.classList.add('slide-menu-control', 'has-parent', 'open')

        if (ulElement?.tagName.toLowerCase() === 'ul') {
            let liElement = document.createElement('li');
            liElement.appendChild(backLink);
            ulElement.insertBefore(liElement, ulElement.firstChild);
        }

        backLink.addEventListener('click', closeSubmenu)
    });
}