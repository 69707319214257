﻿export class ArchiveWebcast {
    static init() {
        window.addEventListener('load', function () {
            const modules = document.querySelectorAll(".js-archive-webcast");

             if (!modules.length) {
                 return false;
             }
        
             modules.forEach(modal => {
                  modal.addEventListener("hide.bs.modal", (e) => {
                    const iframe = e.currentTarget.querySelector("iframe");
                    const iframeConsentSrc = iframe.getAttribute("data-consent-src");
                    let url = iframeConsentSrc !== null ? iframeConsentSrc : iframe.getAttribute("src");

                    iframe.setAttribute("src", url);
                });
             });
        })
    }
}