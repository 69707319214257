class SelectElement {
  constructor(selectContainer, options) {
    this.selectElement = selectContainer.querySelector(
      'select:not([id^="shadow-select"]):not(.ignore-selectric), datalist'
    );
    this.selectContainer = selectContainer;
    this.selectOptions = options || {};
    this.separator = this.selectOptions.multiple?.separator;
    this.currentHighlighted = null;
    this.defaultPlaceholder = this.selectElement.querySelector("option:disabled")?.innerText;
    this.isMobileandNotAutocomplete = this._isMobile() && !this.selectOptions.autocomplete;
    this.init();
    this.bindEvents();
  }

  init() {
    const selectElement = this.selectElement;
    const selectContainer = this.selectContainer;
    const selectID = selectElement.getAttribute("id");
    const selectName = selectElement.getAttribute("name");
    const selectTabIndex = selectElement.getAttribute("tabindex");
    const selectClasses = selectElement.getAttribute("className");
    const selectData = selectElement.getAttribute("data");
    const selectedValues = this._getSelectedValues();

    const selectWrapper = document.createElement("div");
    selectWrapper.classList.add("select-wrapper");
    selectWrapper.classList.add(selectClasses);
    selectWrapper.setAttribute("tabindex", selectTabIndex? selectTabIndex : 0);
    selectContainer.appendChild(selectWrapper);

    if(this.selectOptions.autocomplete) {
      const autocompleteInput = document.createElement("input");
      const selectPlaceholder = selectElement.getAttribute("placeholder");
      autocompleteInput.classList.add("autocomplete-input", "form-control");
      autocompleteInput.setAttribute("type", "text");
      autocompleteInput.setAttribute("placeholder", selectPlaceholder);
      autocompleteInput.setAttribute("tabindex", selectTabIndex? selectTabIndex : 0);
      selectWrapper.appendChild(autocompleteInput);
    }

    const hideSelectElement = document.createElement("div");
    hideSelectElement.classList.add("select-hide-select");
    if(this.isMobileandNotAutocomplete) {
      hideSelectElement.classList.add("select-hide-select-mobile");
    } else {
      hideSelectElement.classList.remove("select-hide-select-mobile");
    }
    hideSelectElement.appendChild(selectElement);
    selectWrapper.appendChild(hideSelectElement);

    const selectBox = document.createElement("div");
    selectBox.classList.add("selectBox");
    selectWrapper.appendChild(selectBox);

    const selectPlaceholder = document.createElement("span");
    selectPlaceholder.classList.add("label");
    selectPlaceholder.innerText = selectedValues? selectedValues : this.defaultPlaceholder;
    selectBox.appendChild(selectPlaceholder);

    const selectArrow = document.createElement("strong");
    selectArrow.classList.add("button");
    selectBox.appendChild(selectArrow);

    const selectItemsWrapper = document.createElement("div");
    selectItemsWrapper.classList.add("select-items");
    selectWrapper.appendChild(selectItemsWrapper);

    const selectScroll = document.createElement("div");
    selectScroll.classList.add("select-scroll");
    selectItemsWrapper.appendChild(selectScroll);

    const selectItems = document.createElement("ul");
    selectItems.setAttribute("tabindex", selectTabIndex? selectTabIndex : 0);
    selectScroll.appendChild(selectItems);

    const selectInput = document.createElement("input");
    selectInput.setAttribute("type", "hidden");
    selectInput.setAttribute("name", selectName);
    selectInput.setAttribute("id", selectID);
    selectInput.setAttribute("tabindex", selectTabIndex? selectTabIndex : 0);
    selectInput.setAttribute("data", selectData);
    selectInput.setAttribute("value", selectedValues);
    selectWrapper.appendChild(selectInput);

    const selectOptionsList = selectElement.querySelectorAll("option");
    selectOptionsList.forEach((option) => {
      const selectOption = document.createElement("li");
      selectOption.setAttribute("data-value", option.value);
      selectOption.setAttribute("data-index", option.index);
      option.innerText = option.innerText.trim();
      option.disabled ? selectOption.classList.add("disabled") : "";
      option.selected ? selectOption.classList.add("selected") : "";
      selectOption.innerText = option.innerText;
      selectItems.appendChild(selectOption);
    });
  }

  bindEvents() {
    const selectItems = this.selectContainer.querySelectorAll(
      ".select-items li:not(.disabled)"
    );
    const selectWrapper = this.selectContainer.querySelector(".select-wrapper");
    const autocompleteInput = this.selectContainer.querySelector(".autocomplete-input");
    const selectInput = selectWrapper.querySelector('.select-items ul');
    selectWrapper.addEventListener("click", this._toggleSelect.bind(this));

    selectWrapper.addEventListener('keydown', function (event) {
      if (event.code === 'Space') {
        event.preventDefault();
        selectWrapper.click();
        return;
      }

      if (event.code === 'Tab' || (event.shiftKey && event.code === 'Tab') || event.code === 'Escape') {
        selectWrapper.classList.remove("select-open");
        return;
      }
    });

    autocompleteInput?.addEventListener("input", this._searchValue.bind(this));
    autocompleteInput?.addEventListener("keydown", this._handleKeys.bind(this));
    //for native select on mobile
    if(this.isMobileandNotAutocomplete) {
      this.selectContainer.querySelector("select").addEventListener("change", this._selectValue.bind(this));
    } else {
      selectItems.forEach((selectItem) => {
        selectItem.addEventListener("click", this._selectValue.bind(this));
      });
    }
    selectInput.addEventListener("keydown", this._handleKeys.bind(this));
    document.addEventListener("scroll", this._isInViewport.bind(this));
    document.addEventListener("click", (event) => {
      if (!event.target.closest(".select-wrapper")) {
        selectWrapper.classList.remove("select-open");
      }
    });
  }

/**
Toggles the open state of the select element.
@param {Event} e - The event object.
@returns {void}
*/
  _toggleSelect(e) {
    if(this.isMobileandNotAutocomplete) return;
    if(this.selectOptions.autocomplete && e.target.classList.contains("autocomplete-input")) {
      return;
    }
    const selectWrapper = this.selectContainer.querySelector(".select-wrapper");
    const selectItems = selectWrapper.querySelector('.select-items ul');
    const selectedIndex = this.selectElement.options.selectedIndex; // get selected index
    selectWrapper.classList.toggle("select-open");
    if (selectWrapper.classList.contains("select-open")) {
      selectItems.focus();
      this._isInViewport();
      this._scrollToSelected(selectedIndex);
    }
  }

/**
Handles the selection of an option from the select element.
@param {Event} event - The event triggered by the user selecting an option.
@returns {void}
*/
  _selectValue(event) {
    const selectElement = this.selectElement;
    const selectContainer = this.selectContainer;
    const selectInput = selectContainer.querySelector('input[type="hidden"]');
    const selectItems = selectContainer.querySelectorAll(".select-items li");
    const selectPlaceholder = selectContainer.querySelector(".select-wrapper .label");

    const selectedIndex = this.isMobileandNotAutocomplete ? event.target.selectedIndex : event.target.getAttribute("data-index");
    const selectValue = this.isMobileandNotAutocomplete? event.target.value : event.target.getAttribute("data-value");
    const selectOption = selectElement.options[selectedIndex];
    const selectText = this.isMobileandNotAutocomplete? selectOption?.innerText : event.target.innerText;

    let selectValues = [];
    let selectTexts = [];
    //select multiple
    if (this.selectOptions.multiple) {
      const selectInputValue = selectInput.getAttribute("value");
      if (selectInputValue !== "") {
        selectValues = selectInput.getAttribute("value").split(this.separator);
        selectTexts = selectPlaceholder.innerText.split(this.separator);
      }
      const selectOptions = selectContainer.querySelectorAll(`option[value="${selectValue}"]`);

      if (selectValues.includes(selectValue)) {
        selectValues.splice(selectValues.indexOf(selectValue), 1);
        selectTexts?.splice(selectTexts.indexOf(selectText), 1);
        selectOptions.forEach((option) => {
          event.target.classList.remove("selected");
          option.selected = false;
        });
      } else {
        selectValues.push(selectValue);
        selectTexts.push(selectText);
        selectOptions.forEach((option) => {
          event.target.classList.add("selected");
          option.selected = true;
        });
      }
      selectInput.setAttribute("value", selectValues.join(this.separator));
      if (this.selectOptions.autocomplete) {
        const autocompleteInput = selectContainer.querySelector(
          ".autocomplete-input"
        );
        autocompleteInput.value = selectTexts.length > 0? `${selectTexts.join(this.separator)}${this.separator} ` : "";
        this._resetAutoComplete(selectItems);
      }
      selectPlaceholder.innerText = selectTexts.length > 0? selectTexts.join(this.separator) : this.defaultPlaceholder;
      if(!this._isMobile()) selectElement.dispatchEvent(new Event("change"));
    } else {
      selectItems.forEach((item) => {
        item.classList.remove("selected");
      });

      event.target.classList.add("selected");
      selectInput.setAttribute("value", selectValue);
      selectPlaceholder.innerText = selectText;
      selectOption.selected = true;
      if(!this._isMobile()) selectElement.dispatchEvent(new Event("change"));
    }
  }

// Retrieves the selected values from a select element.
  _getSelectedValues() {
    const optionsLength = this.selectElement.options.length;
    if (optionsLength > 1) {
      const selectedOptions = Array.from(this.selectElement.options).filter(option => option.selected && !option.disabled);
      const selectedValues = [];
      selectedOptions.forEach((option) => {
        selectedValues.push(option.innerText.trim());
      });
      return selectedValues.join(this.separator);
    } else {
      return this.selectElement.options[0]?.innerText.trim();
    }
  }

// Checks if the select container is in the viewport and adds or removes the "select-above" class accordingly.
  _isInViewport() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const selectWrapper =
      this.selectContainer.querySelector(".select-wrapper");
    const { top, height } = selectWrapper.getBoundingClientRect();
    const itemsHeight =
      selectWrapper.querySelector(".select-items").clientHeight;

    const fitsDown = top + height + itemsHeight <= scrollTop + windowHeight;
    const fitsAbove = top > scrollTop;

    if (!fitsDown && fitsAbove) {
      selectWrapper.classList.add("select-above");
    } else {
      selectWrapper.classList.remove("select-above");
    }
  }

/**
This function scrolls to the selected item in a dropdown menu.
@param {number} index - The index of the selected item.
*/
  _scrollToSelected(index) {
    const items = document.querySelector(".select-items");
    const liItems = document.querySelectorAll(".select-items li");
    const itemsHeight = items.offsetHeight;
    const itemsScroll = document.querySelector(".select-scroll");
    const liHeight = liItems[index]?.offsetHeight;
    const liTop = liItems[index]?.offsetTop;
    const itemsScrollTop = itemsScroll.scrollTop;
    const scrollT = liTop + liHeight * 2;

    const scrollTop =
      scrollT > itemsScrollTop + itemsHeight
        ? scrollT - itemsHeight
        : liTop - liHeight < itemsScrollTop
        ? liTop - liHeight
        : itemsScrollTop;
    items.scrollTop = scrollTop;
  }

  _isMobile() {
    return /android|ip(hone|od|ad)/i.test(navigator.userAgent);
  }

  _nextItem(selectItems, highlighted) {
    return Math.min(highlighted + 1, selectItems.length - 1);
  }

  _previousItem(highlighted) {
    return Math.max(highlighted - 1, 0);
  }
// handles keyboard events on the select element
  _handleKeys(e) {
    const key = e.key;
    const selectItems =
      this.selectContainer.querySelectorAll(".select-items li:not(.hidden)");
    const selectedIndex =
      this.currentHighlighted !== null
        ? this.currentHighlighted
        : this.selectElement.options.selectedIndex; // get selected index

    switch(key) {
      case 'ArrowDown': {
        e.preventDefault();
        const nextItem = this._nextItem(selectItems, selectedIndex);
        if(selectItems[nextItem].classList.contains('disabled')) return;
        this.currentHighlighted = nextItem;
        this._resetHighlighted(selectItems);
        selectItems[nextItem].classList.add('highlighted');
        this._scrollToSelected(this.currentHighlighted);
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        const previousItem = this._previousItem(selectedIndex);
        if(selectItems[previousItem].classList.contains('disabled')) return;
        this.currentHighlighted = previousItem;
        this._resetHighlighted(selectItems);
        selectItems[previousItem].classList.add('highlighted');
        this._scrollToSelected(this.currentHighlighted);
        break;
      }
      case 'Enter': {
        e.preventDefault();
        this._selectHighlighted();
        break;
      }
      case 'Backspace': {
        e.preventDefault();
        if(this.selectOptions.autocomplete) {
          const autocompleteInput = this.selectContainer.querySelector('.autocomplete-input');
          const selectInput = this.selectContainer.querySelector('input[type="hidden"]');
          const selectPlaceholder = this.selectContainer.querySelector(".select-wrapper .label");
          const items = this.selectContainer.querySelectorAll(".select-items li");
          const inputValue = autocompleteInput.value;
          const selectValues = inputValue.trim().split(/;/).filter(Boolean);
          const lastValue = selectValues.pop();
          const newValue = selectValues.join(this.separator);
          if(!newValue) {
            this._resetAutoComplete(items);
          }
          autocompleteInput.value = newValue? `${newValue}; ` : '';
          selectInput.value = newValue;
          selectPlaceholder.innerText = newValue;
          if(!lastValue) return;
          const item = [...selectItems].find(item => item.innerText.toLowerCase().includes(lastValue.trim().toLowerCase()));
          item?.classList.remove('selected');
        }
        break;
      }
      default:
        return;
    }
  }

  _selectHighlighted() {
    const highlighted = this.selectContainer.querySelector('.highlighted');
    if(!highlighted) return;
    highlighted.click();
    highlighted.classList.remove('highlighted');
  }

  _resetHighlighted(selectItems) {
    selectItems.forEach((option) => {
      option.classList.remove("highlighted");
    });
  }

  _resetAutoComplete(selectItems) {
    selectItems.forEach((option) => {
      option.classList.remove("hidden");
    });
  }

// search for the value in the dropdown
  _searchValue() {
    this.selectContainer.querySelector('.select-wrapper').classList.add('select-open');
    const selectItems =
      this.selectContainer.querySelectorAll(".select-items li");
    const inputElement = this.selectContainer.querySelector(".autocomplete-input");
    const inputValue = inputElement.value;
    const searchValue = inputValue.split(this.separator).pop().replace(/^\s+/, "");
    
    selectItems.forEach((item) => {
      const itemText = item.innerText.toLowerCase();
      const searchValueLowerCase = searchValue.toLowerCase();
      if (itemText.includes(searchValueLowerCase)) {
        //highlight text
        const regex = new RegExp(searchValue, "gi");
        const innerText = item.innerText;
        innerText.replace(regex, (match) => {
          const index = innerText.indexOf(match);
          const firstPart = innerText.substring(0, index);
          const secondPart = innerText.substring(index + match.length);
          item.innerHTML = `${firstPart}<span class="highlighted-text">${match}</span>${secondPart}`;
        });
        item.classList.remove("hidden");
      } else {
        item.classList.add("hidden");
        item.innerHTML = item.innerText;
      }
    });
  }
}

export default SelectElement;
