import ImageGalleryCarouselViewModel from './imageGalleryCarouselViewModel'
import ImageGalleryCarouselController from './imageGalleryCarouselController'

(function () {
    const carouselBlockSelector = '.carousel-block--single';

    (function () {
        const blocks = document.querySelectorAll(carouselBlockSelector);

        blocks.forEach(block => {
            const viewModel = ImageGalleryCarouselViewModel(block);
            const controller = new ImageGalleryCarouselController(block, viewModel);

            controller.init();

            window.addEventListener('resize', controller.refresh);
        });
    })();
})();