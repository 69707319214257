const removeClassToDisplayImages = () => {
  document.querySelectorAll('body.gdpr-banner-displayed')[0]?.classList.remove('gdpr-banner-displayed');
};

const checkIfBannerHidden = () => document.getElementById('coiOverlay').style.display === 'none';

if (document.querySelectorAll('body.gdpr-banner-displayed').length) {
  // check if user alredy has Cookie. in which case the banner will not get displayed and therefore images should be visible
  if (document.cookie.split(';').some(item => item.trim().startsWith('CookieInformationConsent'))) {
    removeClassToDisplayImages();
  } else {
    let countDown = 1;
    window.addEventListener('CookieInformationConsentGiven', () => {
      if (countDown === 0) removeClassToDisplayImages();
      countDown--;
      // just safety, in case they change something with the 'CookieInformationConsentGiven' event and it doesn't get triggered once before user closes the modal
      if (checkIfBannerHidden()) removeClassToDisplayImages();
    });
  }
}

// add class to body on page load to indicate that images should be visibility hidden instead of display none so that starts loading in the BG (only for Chromium browsers, FF loads it anyway)
window.addEventListener('load', () => document.querySelectorAll('body.gdpr-banner-displayed')[0]?.classList.add('loaded'));
