const ImageGalleryCarouselViewModel = (blockElement) => {
    const galleryItemSelector = '[data-carousel-slide-item]';
    const arrowLeftSelector = '[data-scroll-left]';
    const arrowRightSelector = '[data-scroll-right]';

    const carouselIndicatorDotsSelector = '[data-carousel-indicator-dot]';
    const slideIndicatorSyncSelector = '[data-slide-indicator-sync]';
    const showMoreBtnsSelector = '[data-gallery-show-more]';
    const showLessBtnsSelector = '[data-gallery-show-less]';
    const deficiencyPageSelector = '.deficiency-gallery';

    const viewModel = {
        galleryItems: blockElement.querySelectorAll(galleryItemSelector),
        arrowLeft: blockElement.querySelector(arrowLeftSelector),
        arrowRight: blockElement.querySelector(arrowRightSelector),
        slidesToSyncWithIndicators: [...blockElement.querySelectorAll(slideIndicatorSyncSelector)],
        getIndicatorDots: () => {
            return blockElement.querySelectorAll(carouselIndicatorDotsSelector)
        },
        showMoreBtns: blockElement.querySelectorAll(showMoreBtnsSelector),
        showLessBtns: blockElement.querySelectorAll(showLessBtnsSelector),
        showMoreBtnHeight: 25,
        isDeficiencyPage: !!document.querySelector(deficiencyPageSelector),
    };

    return viewModel;
}

export default ImageGalleryCarouselViewModel;
