﻿export class Tabs {
    constructor() {
		const tabsLocalStorageKey = `tabs.${window.location.pathname.slice(1).replaceAll('/', '-').slice(0, -1)}`;
		const navTabs = [...document.querySelectorAll('.nav-tabs a')];
		if(!navTabs) return;

		window.addEventListener("debouncedresize", () => {
			setTimeout(() => {
			}, 375);
		});

		navTabs.forEach(tab => tab.addEventListener('shown.bs.tab', function() {
			window.dispatchEvent(new Event('resize'));
			// save index of the last selected tab
			sessionStorage.setItem(tabsLocalStorageKey, navTabs.indexOf(this));
		}));

		// tab preseletion
		const selectedTab = sessionStorage.getItem(tabsLocalStorageKey);
		if (selectedTab){
			navTabs[selectedTab]?.click();
		}
    }
}