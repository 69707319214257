import { getCookie } from '../common/helpers/index';
import { polyfillArrayFrom } from '../common/polyfill/index';
import { capitalizeFirstLetter } from '../common/helpers';
// detect if need polyfill for Array.from, if does then init the polyfill
polyfillArrayFrom.init();

export const makeIframeBlockableByCookieInformation = (elmt, category) => {
    elmt.setAttribute('data-category-consent', `cookie_cat_${category}`); // select cookie category to condition to display on
    elmt.setAttribute('data-consent-src', elmt.src);
    elmt.setAttribute('src', '');

    return elmt;
};

const placeholderThumbnail = elmt => {
    // youtube thumbnails
    if (elmt.dataset.consentSrc?.includes('youtube') && elmt.id.split('video-').length > 1) {
        return `<img style="background-image:url('https://img.youtube.com/vi/${elmt.id.split('video-')[1].split('?')[0]}/hqdefault.jpg');" class="placeholder-thumbnail" />`;
    } else if (elmt.dataset.consentSrc?.includes('youtube') && elmt.dataset.consentSrc.split('https://www.youtube.com/embed/').length > 1) {
        return `<img style="background-image:url('https://img.youtube.com/vi/${elmt.dataset.consentSrc.split('https://www.youtube.com/embed/')[1].split('?')[0]}/hqdefault.jpg');" class="placeholder-thumbnail" />`;
    } else {
        return '';
    }
};

const isCategorizedCookie = category => ['functional', 'analytical', 'marketing'].includes(category);

const placeholderText = category => {
    if (isCategorizedCookie(category)) {
        return document.getElementsByTagName('meta')[`CookieInformationPlaceholder${capitalizeFirstLetter(category)}`] !== undefined ? document.getElementsByTagName('meta')[`CookieInformationPlaceholder${capitalizeFirstLetter(category)}`].content : '';
    } else {
        return `This media item is disabled because it requires consent for ${category} cookies. Click here to open cookie settings`;
    }
};

const placeholderElement = (category, elmt) => {
    const dataCategory = isCategorizedCookie(category) ? `cookie_cat_${category}` : 'cookie_cat_functional';
    return `<div class="consent-placeholder ${placeholderThumbnail(elmt) === '' ? ' no-thumbnail ' : ''}" data-category="${dataCategory}" onclick="CookieConsent.renew()" style="display: ${displayValueInsideTemplateLiteralBlock(category)}">
                <div class="consent-placeholder-content">
                    <img src="Frontend/assets/images/eye-off.svg" alt="" class="visibility-icon" />
                    <p>${placeholderText(category)}</p>
                    ${placeholderThumbnail(elmt)}
                </div>
            </div>`;
};

export const addPlaceholderAndReload = (elmt, category, requiresReload) => {
    elmt.parentElement.insertAdjacentHTML('beforeend', placeholderElement(category, elmt));
    if (requiresReload || getCookie('CookieInformationConsent')) {
        window.CookieInformation.loadConsent();
    }
    stopHidingIframe(elmt);
};

const stopHidingIframe = elmt => elmt.display = 'block';

const marketingCookiesProviders = ['youtube'];
const statisticCookiesProviders = [];
const functionalCookiesProviders = ['vimeo'];
const unblockedIframesProviders = [];

const iframeCategoryIdentifier = elmt => {

    if (elmt.src.length || (elmt.dataset.src && elmt.dataset.src?.length) || (elmt.dataset.consentSrc && elmt.dataset.consentSrc?.length)) {
        let srcToCheck;
        if (elmt.src.length) {
            srcToCheck = elmt.src;
        } else if (elmt.dataset.src?.length) {
            srcToCheck = elmt.dataset.src;
        } else {
            srcToCheck = elmt.dataset.consentSrc;
        }

        const hostname = srcToCheck.match(/:\/\/(.[^/]+)/)[1];

        if (unblockedIframesProviders.some(url => hostname.includes(url))) {
            return {
                category: 'unblocked-list',
                placeholderTextMetaName: '',
            };
        } else if (marketingCookiesProviders.some(url => hostname.includes(url))) {
            return {
                category: 'cookie_cat_marketing',
                placeholderTextMetaName: 'CookieInformationPlaceholderMarketing',
            };
        } else if (statisticCookiesProviders.some(url => hostname.includes(url))) {
            return {
                category: 'cookie_cat_statistic',
                placeholderTextMetaName: 'CookieInformationPlaceholderAnalytical',
            };
        } else if (functionalCookiesProviders.some(url => hostname.includes(url))) {
            return {
                category: 'cookie_cat_functional',
                placeholderTextMetaName: 'CookieInformationPlaceholderFunctional',
            };
        } else {
            return {
                category: 'cookie_cat_functional',
                placeholderTextMetaName: 'CookieInformationPlaceholderFunctional',
            };
        }
    }
};

const makeIframeParentRelative = elmt => elmt.parentElement.style.position = 'relative';

export const iframeCIfixer = elmt => {
    const elmtCategory = iframeCategoryIdentifier(elmt);

    if (elmtCategory.category === 'unblocked-list') return;

    elmt.dataset.consentSrc = elmt.src;
    elmt.dataset.categoryConsent = elmtCategory.category;
    hidesIframeSrcDependingOfCookie(elmt);
    makeIframeParentRelative(elmt);
    const placeholder = placeholderElement(elmtCategory.category.split('cookie_cat_')[1], elmt);
    elmt.insertAdjacentHTML('afterend', placeholder);
};

const isAlreadyCIReady = elmt => elmt.dataset.consentSrc !== undefined && elmt.dataset.categoryConsent !== undefined;

const retroactiveTinyMCEIframeCIFixer = iframes => {
    [...iframes].forEach(elmt => {
        if (!isAlreadyCIReady(elmt) && (elmt.src.length || elmt.consentSrc.length)) {
            iframeCIfixer(elmt);
        } else {
            setDisplayForCIPlaceholder(elmt.closest('.o-common-block').querySelector('.consent-placeholder'), elmt.dataset.categoryConsent);
        }
    });
};

const hidesIframeSrcDependingOfCookie = iframe => (CookieInformation.getConsentGivenFor(iframe.dataset.categoryConsent) ? null : iframe.src = '');

const setDisplayPlaceholderForServerRenderedPlaceholders = iframesPlaceholders => [...iframesPlaceholders].map(placeholder => setDisplayForCIPlaceholder(placeholder, placeholder.dataset.category));

const setDisplayForCIPlaceholder = (placeholder, category) => !CookieInformation.getConsentGivenFor(category) ? placeholder.style.display = 'block' : placeholder.style.display = 'none';
const displayValueInsideTemplateLiteralBlock = category => !CookieInformation.getConsentGivenFor(`cookie_cat_${category}`) ? 'block' : 'none';

const isCookieInformationDefined = typeof CookieInformation !== 'undefined';

// This calculation will add extra space inside it's container when the consent placeholder text falls outside of the iframe sizes.
const addHeightForShortIframes = (placeholder) => {
    const consentPlaceholder = placeholder;
    const placeholderParent = consentPlaceholder.parentElement;
    const placeholderIframeSibling = placeholderParent.getElementsByTagName('iframe')[0];
    const placeholderContentText = consentPlaceholder.getElementsByTagName('p')[0];
    const placeholderImageHeight = 100;
    const shouldSkip = parent => parent.classList.contains('video-item');
    if (!shouldSkip(placeholderParent)) {
        // cookies accepted
        if (consentPlaceholder.style.display === 'none') {
            placeholderParent.style.paddingBottom = 0;
            return;
        }
        // cookies declined
        if (placeholderIframeSibling.clientHeight < (placeholderContentText.clientHeight + placeholderImageHeight)) {
            placeholderParent.style.paddingBottom = `${(placeholderContentText.clientHeight + placeholderImageHeight) - placeholderIframeSibling.clientHeight}px`;
        }
    }
}

function initMutationObserver() {
    return new MutationObserver((mutations) => {
        mutations.forEach((record) => {
            addHeightForShortIframes(record.target);
        })
    });
}

const cookiePlaceholderObserver = initMutationObserver();

if (isCookieInformationDefined) {
    // this event also fires on page load
    window.addEventListener('CookieInformationConsentGiven', () => {
        // Azure Application Insights store statistical cookies (ai_user and ai_session); only fire if appInsights exists; it's injected by Azure and it doesn't exist on local environment, so we want to avoid console errors
        if (typeof appInsights !== 'undefined') {

            appInsights.config.isCookieUseDisabled = true; // prevent azure cookies from loading at all, then re-enable if the user chooses to do so

            if (!CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {

                let appInsightsCookies = ['ai_user', 'ai_session'];

                [...appInsightsCookies].forEach(cookie => {
                    document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                });
            }

            if (CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {

                appInsights.config.isCookieUseDisabled = false;
            }
        }

        const consentPlaceholders = document.getElementsByClassName('consent-placeholder') || [];
        for (const consentPlaceholder of consentPlaceholders) {
            addHeightForShortIframes(consentPlaceholder);
            cookiePlaceholderObserver.observe(consentPlaceholder, { attributes: 'true', attributeFilter: ['style'] });
        }
    });
}

cookiePlaceholderObserver.disconnect();

const placeholdersForIframeBlocks = document.querySelectorAll('.iframeblockmodel .consent-placeholder');
const tinyMCEIframes = document.querySelectorAll('.o-common-block .common-text iframe');
const placeholdersForArchiveBlocks = document.querySelectorAll('.archiveblockmodel .consent-placeholder');

if (placeholdersForIframeBlocks.length && isCookieInformationDefined) setDisplayPlaceholderForServerRenderedPlaceholders(placeholdersForIframeBlocks);
if (tinyMCEIframes.length && isCookieInformationDefined) retroactiveTinyMCEIframeCIFixer(tinyMCEIframes);
if (placeholdersForArchiveBlocks.length && isCookieInformationDefined) setDisplayPlaceholderForServerRenderedPlaceholders(placeholdersForArchiveBlocks);
