const Constants = {
    body: document.body,
    logo: document.querySelector('.m-header-logo'),
    mmHeaderContainer: document.querySelector('[data-mm-header-container]'),
    mobileMenu: document.querySelector('[data-mobilemenu]'),
    dropdownMobileMenu: document.querySelector('[data-dropdown-header-container]'),
    headerInner: document.querySelector('[data-mobile-header]'),
    desktopBannerImage: document.querySelector('.desktop[data-banner-image]'),
    menus: document.querySelectorAll('[data-mobile-menu]'),
    menuNav: document.querySelector('[data-mobile-menu-nav]'),
    menuTrigger: document.querySelector('[data-menu-trigger]'),
    subitemsTrigger: document.querySelectorAll('[data-subitems-trigger]'),
    menuItemClassName: 'has-subitems',
    search: document.querySelector('[data-mobile-search]'),
    searchTrigger: document.querySelector('.js-mm-search-trigger'),
    searchInput: document.querySelector('[data-search-input]'),
    countryNav: document.querySelector('[data-mobile-country-nav]'),
    countryTrigger: document.querySelector('[data-country-trigger]'),
    submenuContainer: document.querySelector('#mm-submenu')?.parentElement,
    submenuTrigger: document.querySelector('[data-mobile-submenu]'),
    mainNavigationBreadcrumbsSelector: '.main-white-menu .breadcrumbs-wrap',
    getMainNavigationBreadcrumbs: () => document.querySelector(Constants.mainNavigationBreadcrumbsSelector),

    // because of an absolute position of banner images, it's necessary to move them whenever the search is triggered
    bannerImages: document.querySelectorAll('[data-banner-image]'),

    // yellow crisis modal used for heights calculation
    crisisModal: document.querySelector('.crisis-modal'),
    crisisModalCloseBtn: document.querySelector('.crisis-modal .close'),
}

export default Constants;