﻿export class ContentCollapse {
  static init() {
    document.addEventListener('DOMContentLoaded', () => {
      const expandableTexts = document.querySelectorAll('.js-collapse-height');
      const triggers = document.querySelectorAll('.js-collapse-height-trigger');

      const isTextClamped = textElement => (textElement.scrollHeight !== textElement.clientHeight);

      const hideExpandButton = textElement => {
        const trigger = document.querySelector(`button.js-collapse-height-trigger[data-target=${textElement.id}]`);
        trigger.classList.remove('a-display-block-desktop');
        trigger.classList.add('d-none');
      };

      expandableTexts.forEach(textElement => {
        if (!isTextClamped(textElement)) {
          hideExpandButton(textElement);
        }
      });

      triggers.forEach(trigger => trigger.addEventListener('click', () => {
        document.getElementById(trigger.dataset.target).classList.toggle('a-collapse-height');
        trigger.querySelector('.icon').classList.toggle('open');
        trigger.querySelectorAll('.showtoggle').forEach(node => node.classList.toggle('d-none'));
      }));
    });
  }
}