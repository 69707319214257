export class TableBehavior {
  constructor() {
    const basiContentBlockModelList = document.querySelectorAll(
      ".basiccontentblockmodel"
    );
    basiContentBlockModelList?.forEach((basiContentBlockModel) => {
      const tables = basiContentBlockModel.querySelectorAll("table");
      tables?.forEach((table) => {
        table.closest(".common-text")?.classList.add("has-table");
      });
    });
  }
}
